/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import ConfigApi from '../../../../../configs/ConfigApi';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import AxiosAuth from '../../../../utils/AxiosAuth';

const RegistrationOtpBody = ({
    signIn,
    setShow,
    optConfig,
    setOptConfig,
    // openLoginModal,
}) => {
    const refOtp = useRef();
    const { setMessage } = useTemplate();
    const [countTime, setCountTime] = useState(optConfig.sendingDelay);

    const handleVerifyNowClick = () => {
        setMessage('Verifying OTP...');

        if (!refOtp?.current?.value) {
            setMessage('Please enter OTP');
        } else {
            AxiosAuth.post(`${ConfigApi.API_REGISTRATION}phone/verify/`, {
                name: optConfig?.formData?.name,
                countryCode: optConfig?.formData?.countryCode,
                phone: optConfig?.formData?.phone,
                key: optConfig?.key,
                otp: refOtp.current.value,
                password: optConfig?.formData?.password,
            }).then(({ data }) => {
                setMessage(data.message);

                if (data.error === 0) {
                    // --Login & Creating Local User
                    signIn(data.uniqueId, optConfig?.formData?.password);

                    setShow(false);
                    setOptConfig(null);
                }
            });
        }
    };

    const handleResendSendClick = () => {
        setMessage('Resending OTP...');

        AxiosAuth.post(`${ConfigApi.API_REGISTRATION}phone/resend-otp/`, {
            countryCode: optConfig?.formData?.countryCode,
            phone: optConfig?.formData?.phone,
            resend: true,
        }).then(({ data }) => {
            setMessage(data.message);

            if (data.error === 0) {
                setOptConfig((cuVal) => ({ ...cuVal, sendingStatus: 'count-down', key: data.key }));
                setCountTime(optConfig.sendingDelay);
            }
        });
    };

    useEffect(() => {
        if (countTime > 0) {
            const timer = setTimeout(() => {
                setCountTime((cuTime) => cuTime - 1);
            }, 1000);

            return () => {
                clearTimeout(timer);
            };
        }

        return null;
    }, [countTime]);

    useEffect(() => {
        if (optConfig.sendingStatus === 'count-down' && countTime === 0) {
            setOptConfig((cuVal) => ({ ...cuVal, sendingStatus: 'resend' }));
        }
    }, [countTime, optConfig.sendingStatus, setOptConfig]);

    return (
        <div className="sss">
            <div>
                <p className="custom-otp-title">
                    We&apos;ve sent a code to {optConfig?.formData?.phone}, Please enter the OTP
                    code.
                </p>
            </div>

            <Form.Group className="custom-login-input">
                <Form.Control
                    ref={refOtp}
                    className="custom-input-otp text-center"
                    type="text"
                    pattern="\d*"
                    placeholder="OTP"
                    maxLength={6}
                />
            </Form.Group>

            {optConfig.sendingStatus === 'count-down' && (
                <div className="custom-otp-expire">
                    Code expires in: <b>{countTime}</b>
                </div>
            )}

            <button type="button" className="custom-verify-btn" onClick={handleVerifyNowClick}>
                Confirm
            </button>

            <button
                type="button"
                className="custom-resend-btn"
                onClick={handleResendSendClick}
                disabled={countTime !== 0}
            >
                <p>Send Again</p>
                {optConfig.sendingStatus === 'count-down' && <div>{countTime}</div>}
            </button>

            <Form.Group className="custom-login-footer">
                <p>
                    Back to{' '}
                    <span
                        onClick={() => {
                            setOptConfig(null);
                        }}
                    >
                        Registration
                    </span>
                </p>
            </Form.Group>
        </div>
    );
};

export default RegistrationOtpBody;
