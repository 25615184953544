/* eslint-disable no-unused-vars */
import React from 'react';
import { Card, Tab, Tabs } from 'react-bootstrap';
import ProductPropertyDescription from './ProductPropertyDescription';
import ProductPropertyReview from './ProductPropertyReview';
import ProductPropertySpecification from './ProductPropertySpecification';

function ProductDescriptionVariant({ data }) {
    if (data) {
        return Object.keys(data).map((key) => (
            <p key={key}>
                {key} :
                {data[key].map((item) => (
                    <b style={{ padding: '5px' }} key={item}>
                        {item},
                    </b>
                ))}
            </p>
        ));
    }

    return null;
}

export default function ProductPropertyCard({ data, productCategoryList }) {
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Card style={{ borderRadius: '16px', marginTop: 25, marginBottom: 20 }} variant="outlined">
            <Card.Body>
                <Tabs
                    defaultActiveKey="profile"
                    id="uncontrolled-tab-example"
                    className="product-property mb-3"
                >
                    <Tab eventKey="home" title="Description">
                        <ProductPropertyDescription
                            value="1"
                            data={data}
                            productCategoryList={productCategoryList}
                            ProductDescriptionVariant={ProductDescriptionVariant}
                        />
                    </Tab>
                    <Tab eventKey="profile" title="Speacification">
                        <ProductPropertySpecification
                            value="2"
                            data={data}
                            productCategoryList={productCategoryList}
                            ProductDescriptionVariant={ProductDescriptionVariant}
                        />
                    </Tab>
                    <Tab eventKey="profile2" title="Review">
                        <ProductPropertyReview value="3" />
                    </Tab>
                </Tabs>
                <div />
            </Card.Body>
        </Card>
    );
}
