import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthProvider';
import ReactGA4 from '../../../utils/ReactGA4';

function UsersLogoutPage() {
    const { signOut } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        signOut();
        navigate('/');
    });

    return (
        <div>
            <ReactGA4 />
        </div>
    );
}

export default UsersLogoutPage;
