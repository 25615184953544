import React from 'react';
import { Container } from 'react-bootstrap';
import ProductItem from '../../products/components/ProductItem';

function BestSellProducts({ products }) {
    return (
        <section className="top-sell-product-section">
            <Container>
                <h4 className="custom-typo">
                    <span>Best Sell</span>
                </h4>
                <div className="row row-m-product">
                    {products.slice(0, 10).map((item) => (
                        <div
                            className="col mb-4 col-m-product top-sell-product-section-colI5"
                            key={item.id}
                        >
                            <ProductItem itemData={item} />
                        </div>
                    ))}
                </div>
            </Container>
        </section>
    );
}

export default BestSellProducts;
