import { combineReducers } from 'redux';
import InitSetReducer from './reducers/InitSetReducer';
import ProductDetailsReducer from './reducers/ProductDetailsReducer';
import ShopInitReducer from './reducers/ShopInitReducer';

const rootReducer = combineReducers({
    initData: InitSetReducer,
    shopData: ShopInitReducer,
    productDetails: ProductDetailsReducer,
});

export default rootReducer;
