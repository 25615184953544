/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import DownArrowIcon from '../../../../assets/images/DownArrow.svg';
import EmailIcon from '../../../../assets/images/emailIcon.svg';
import RightArrowIcon from '../../../../assets/images/RightArrow.svg';
import TopIcon from '../../../../assets/images/success-page-top-icon.svg';
import UpArrowIcon from '../../../../assets/images/UpArrow.svg';
import ConfigApi from '../../../../configs/ConfigApi';
import { useAuth } from '../../../contexts/AuthProvider';
import AxiosAuth from '../../../utils/AxiosAuth';
import PageProperties from '../../../utils/PageProperties';
import ReactGA4 from '../../../utils/ReactGA4';

function OrderSuccessPage() {
    const { currentUser } = useAuth();
    const [invoiceItemData, setInvoiceItemData] = useState([]);
    const [invoiceData, setInvoiceData] = useState([]);
    const { invoiceId } = useParams();
    const [showDetail, setShowDetail] = useState(false);
    useEffect(() => {
        // Collect API Data
        AxiosAuth.currentUserAuth(currentUser)
            .get(ConfigApi.API_INVOICE.replace(':invoiceId', invoiceId))
            .then((response) => {
                setInvoiceData(response.data.invoiceInfo);
                setInvoiceItemData(response.data.invoiceItems);
            })
            .catch((err) => {
                console.log(err);
            });

        // Collect invoice data
    }, [currentUser, invoiceId]);

    if (!invoiceData) {
        return null;
    }
    PageProperties('Order Success');

    return (
        <>
            <ReactGA4 />
            <section className="user-section" style={{ backgroundColor: '#FFFFFF' }}>
                <Container>
                    <div className="success-order-top">
                        <img src={TopIcon} alt="" />
                    </div>
                    <div className="success-order-ttitle">
                        <h1>Thnak You</h1>
                        <p>Your order has been received </p>
                        <p>
                            Your order number is <b>#{invoiceData.id}</b>
                        </p>
                    </div>
                    <div className="success-order-info">
                        <p>Your Order</p>
                        <div className="success-order-info-box">
                            <div className="success-order-info-box-img">
                                <div className="success-order-info-img">
                                    {invoiceItemData?.map((imgAr) => (
                                        <img src={imgAr.product_image} alt="" key={imgAr.id} />
                                    ))}
                                </div>
                                <div className="success-order-info-delivery">
                                    {invoiceData?.dateIssue}
                                </div>
                            </div>
                            <div className="success-order-info-box-link">
                                <div className="success-order-info-box-link-breadcrumb">
                                    For more details, track your delivery status under{' '}
                                    <Link to="/"> My Account</Link>
                                    <img
                                        src={RightArrowIcon}
                                        alt=""
                                        style={{ marginLeft: 8 }}
                                    />{' '}
                                    <Link to="/"> My Order</Link>
                                </div>
                                <div className="success-order-info-box-link-btn">
                                    <button type="button">
                                        <Link to={`/user/order-details/${invoiceData.id}/`}>
                                            View Order
                                        </Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="success-order-info-notifi">
                            <img src={EmailIcon} alt="" />
                            We’ve sent a confirmation email with the order details.
                        </div>
                        <div
                            className="success-order-summary-sub"
                            style={{ display: showDetail ? 'flex' : 'none' }}
                        >
                            <h3>Order Summery</h3>
                            <img src={UpArrowIcon} alt="" onClick={() => setShowDetail(false)} />
                            <h4>Subtotal ({invoiceItemData?.length} Items)</h4>
                            <h5>{invoiceData?.totalAmountOffer}</h5>
                            <h4>Delivery Charge</h4>
                            <h5>{invoiceData?.shippingCharge}</h5>
                            <div className="success-order-summary-divider" />
                        </div>

                        <div className="success-order-summary">
                            <h3>Order Summery</h3>
                            <span className={showDetail ? 'success-order-summary-span' : ''}>
                                {(
                                    invoiceData?.totalAmountOffer + invoiceData?.shippingCharge
                                ).toFixed(2)}
                            </span>
                            {showDetail ? (
                                <small>VAT included, where applicable</small>
                            ) : (
                                <img
                                    src={DownArrowIcon}
                                    alt=""
                                    onClick={() => setShowDetail(true)}
                                />
                            )}
                        </div>
                        <div className="success-order-btn">
                            <button type="button">
                                <Link to="/">Continue Shopping</Link>
                            </button>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
}

export default OrderSuccessPage;
