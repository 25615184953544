import { faCartArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PlaceholderImg from '../../../../assets/images/placeholder-image.png';
import CurrencyIcon from '../../../utils/CurrencyIcon';
import CustomBadge from '../../../utils/CustomBadge';
import { ReviewStar } from '../../users/modals/ModelReviews';
import ProductVariantModal from './ProductVariantModal';

function BuyNowWithVariation({ myClassName, icon, title, size, productData }) {
    const [modalShow, setModalShow] = useState(false);

    const handleModalClose = () => setModalShow(false);

    const handleModalShow = () => {
        setModalShow(true);
    };

    return (
        <>
            <Button
                variant="outline-info"
                className={[myClassName || 'ThemeBtn'].join(' ')}
                size={size || 'sm'}
                onClick={handleModalShow}
            >
                {icon && <FontAwesomeIcon icon={icon} />}
                {icon ? ' ' : ''} {title || 'Button'}
            </Button>

            {modalShow && (
                <ProductVariantModal
                    modalShow={modalShow}
                    handleModalClose={handleModalClose}
                    productData={productData}
                />
            )}
        </>
    );
}

function ProductItem({ itemData }) {
    return (
        <Card className="product-section-card">
            <CustomBadge type={itemData?.stock_qty <= 0 ? 'stock_out' : 'new'} />
            {/* {itemData?.stock_qty <= 0 ? (
                <img
                    className="RubberStamp"
                    src={require('../../../../assets/images/stock_out.png').default}
                    alt=""
                />
            ) : null} */}

            <Link to={itemData?.url}>
                {itemData?.default_image ? (
                    <Card.Img
                        className="product-section-imgBox"
                        src={`https://www.optimizee.xyz/images/${btoa(
                            itemData?.default_image
                        )},200,300.jpg`}
                    />
                ) : (
                    <Card.Img className="product-section-imgBox" src={PlaceholderImg} />
                )}
            </Link>

            <Card.Body className="product-section-card-body">
                <Card.Text className="product-section-title-text">
                    <Link to={itemData?.url}>{itemData?.title}</Link>
                </Card.Text>
                <div className="product-section-pricing">
                    <strong>
                        <CurrencyIcon /> {itemData?.priceOffer}
                    </strong>

                    <span>
                        {itemData?.price !== itemData?.priceOffer ? (
                            <>
                                <CurrencyIcon /> {itemData?.price}
                            </>
                        ) : null}
                    </span>

                    <div className="product-section-rating">
                        <ReviewStar currentReview={itemData?.review_point} />
                    </div>
                    <BuyNowWithVariation
                        myClassName="product-section-buyNow"
                        icon={faCartArrowDown}
                        size="sm"
                        title="ADD TO CART"
                        productId={itemData?.id}
                        productData={itemData}
                    />
                    {/* {itemData.variation_header !== null ? (
                        <BuyNowWithVariation
                            myClassName="product-section-buyNow"
                            icon={faCartArrowDown}
                            size="sm"
                            title="ADD TO CART"
                            productId={itemData.id}
                            productData={itemData}
                        />
                    ) : (
                        <BuyNowButton
                            myClassName="product-section-buyNow"
                            icon={faCartArrowDown}
                            size="sm"
                            title="ADD TO CART"
                            productId={itemData.id}
                            selectedVariation={{}}
                            isDisable={false}
                            shopId={itemData.shopSl}
                            newQuantity={1}
                        />
                    )} */}
                </div>
            </Card.Body>
        </Card>
    );
}

export default ProductItem;
