import React from 'react';

export default function StatusBtn({ status }) {
    let bgColor = '#dd6969';
    let tColor = 'tColor';
    if (status === 'processing') {
        bgColor = '#FFF6E8';
        tColor = '#FFA41D';
    }
    if (status === 'pending') {
        bgColor = '#FFF1E7';
        tColor = '#FF7410';
    }
    if (status === 'prepared') {
        bgColor = 'rgba(86, 127, 219, 0.2)';
        tColor = 'rgb(86, 127, 219)';
    }
    if (status === 'delivered') {
        bgColor = '#E5F8F5';
        tColor = '#00B59C';
    }
    if (status === 'shipped') {
        bgColor = '#F2F6E9';
        tColor = '#79A323';
    }
    if (status === 'canceled') {
        bgColor = '#FFE5E6';
        tColor = '#FE0002';
    }
    if (status === 'refunded') {
        bgColor = '#F7EDFF';
        tColor = '#B659FF';
    }
    if (status === 'active') {
        bgColor = '#F1FEFE';
        tColor = '#04F4E6';
    }
    return (
        <button
            type="button"
            style={{
                padding: '5px 15px',
                color: tColor,
                background: bgColor,
                fontSize: '10px',
                border: 'none',
                width: 87,
                height: 25,
            }}
        >
            {status.toUpperCase()}{' '}
        </button>
    );
}
