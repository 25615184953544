import React from 'react';
import ProductItem from '../ProductItem';

function ProductRelatedCard({ relatedProducts }) {
    return (
        <div style={{ borderRadius: '16px', marginTop: 25, marginBottom: 20 }}>
            <div className="row row-m-product">
                <div className="col-12  mb-3">
                    <h4>Related Products</h4>
                </div>
                {relatedProducts?.map((row) => (
                    <div className="col mb-4 col-m-product excl-product-section-colI5" key={row.id}>
                        <ProductItem itemData={row} />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ProductRelatedCard;
