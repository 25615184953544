import React, { useRef } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import ConfigApi from '../../../../configs/ConfigApi';
import { useAuth } from '../../../contexts/AuthProvider';
import { useTemplate } from '../../../contexts/TemplateProvider';
import AxiosAuth from '../../../utils/AxiosAuth';

function UserPasswordChangeModal({ show, setShow }) {
    const { currentUser } = useAuth();
    const refPass = useRef();
    const refRepass = useRef();
    const refOldPass = useRef();

    const { setMessage } = useTemplate();

    const handlePasswordChange = () => {
        AxiosAuth.currentUserAuth(currentUser)
            .post(ConfigApi.API_USER_UPDATE_PASSWORD, {
                password: refPass.current.value,
                rePassword: refRepass.current.value,
                oldPassword: refOldPass.current.value,
            })
            .then(({ data }) => {
                setMessage({ text: `${data.message}`, timeout: 10000 });
                if (data.error === 0) {
                    setShow(false);
                }
            });
    };

    return (
        <Modal
            size="md"
            show={show}
            onHide={() => {
                setShow(false);
            }}
            centered
            aria-labelledby="example-modal-sizes-title-lg"
            contentClassName="custom-profile-change-content btn-close-custom"
        >
            <Modal.Header closeButton>
                <Modal.Title>Change Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="custom-login-input">
                        <Form.Label className="custom-label">Old Password</Form.Label>
                        <Form.Control
                            className="custom-input"
                            type="password"
                            placeholder="*******"
                            name="old_password"
                            ref={refOldPass}
                        />
                    </Form.Group>

                    <Form.Group className="custom-login-input">
                        <Form.Label className="custom-label">New Password</Form.Label>
                        <Form.Control
                            className="custom-input"
                            type="password"
                            placeholder="*******"
                            name="old_password"
                            ref={refPass}
                        />
                    </Form.Group>
                    <Form.Group className="custom-login-input">
                        <Form.Label className="custom-label">Re New Password</Form.Label>
                        <Form.Control
                            className="custom-input"
                            type="password"
                            placeholder="*******"
                            name="re_password"
                            ref={refRepass}
                        />
                    </Form.Group>

                    <Button
                        variant="primary"
                        type="button"
                        onClick={handlePasswordChange}
                        className="custom-login-btn"
                        name="birthDate"
                    >
                        Change
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default UserPasswordChangeModal;
