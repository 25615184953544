import React, { useEffect, useState } from 'react';
import NoProductImg from '../../../../assets/images/NoProduct2.png';
import ConfigApi from '../../../../configs/ConfigApi';
import { useAuth } from '../../../contexts/AuthProvider';
import AxiosAuth from '../../../utils/AxiosAuth';
import FindInArray from '../../../utils/FindInArray';
import InitInfo from '../../../utils/InitInfo';
import PageProperties from '../../../utils/PageProperties';
import ReactGA4 from '../../../utils/ReactGA4';
import ProductItem from '../../products/components/ProductItem';

function OrderDetailRelatedProduct({ products, wishList }) {
    const FindArr = new FindInArray();
    FindArr.init(products, 'id');
    return (
        <div className="row">
            {wishList?.map((wishId) => {
                const itemData = FindArr.find(wishId);
                return (
                    <>
                        {itemData ? (
                            <div className="col-6 col-lg-4 mb-4" key={wishId}>
                                <ProductItem itemData={itemData} />
                            </div>
                        ) : (
                            ''
                        )}
                    </>
                );
            })}
        </div>
    );
}

function UserWishlistPage() {
    const { products } = InitInfo();
    const [wishList, setWishList] = useState();
    const { currentUser } = useAuth();

    useEffect(() => {
        if (currentUser) {
            AxiosAuth.currentUserAuth(currentUser)
                .get(ConfigApi.API_USER_WISHLIST)
                .then((response) => {
                    setWishList(response.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [currentUser]);
    PageProperties('Wishlist');
    return (
        <div className="col-12 col-lg-9">
            <ReactGA4 />
            <div className="order-section-detail-wishlist">
                {wishList?.length > 0 ? (
                    <OrderDetailRelatedProduct products={products} wishList={wishList} />
                ) : (
                    <div className="order-section-no-order">
                        <div className="order-section-no-order-img">
                            <img src={NoProductImg} alt="No Product" />
                        </div>
                        <div className="order-section-no-order-title">
                            <h5>There Are No Favorite .</h5>
                            <h6>You can see your Favorite Products here.</h6>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default UserWishlistPage;
