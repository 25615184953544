import React from 'react';

function OptionBox({ children, show, handleHide, myWidth }) {
    return (
        <>
            <div
                tabIndex={0}
                role="button"
                className={['top-box-foreground', show ? 'show' : ''].join(' ')}
                onClick={handleHide}
            />
            <div className={['top-box', show ? 'show' : ''].join(' ')} style={{ width: myWidth }}>
                <div>{children}</div>
            </div>
        </>
    );
}

export default OptionBox;
