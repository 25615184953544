/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Container } from 'react-bootstrap';

function TermsSection() {
    return (
        <section className="product-detail-section">
            <Container>
                <h3>Terms and Conditions</h3>
                <p>
                    With time 𝗥𝗨𝗣𝗞𝗔𝗧𝗛𝗔 may modify or apply new terms and conditions to this website.
                    Please do visit this section periodically for your own clarification. You can
                    also contact us for any query at our Facebook official page or our hotline
                    numbers.
                </p>
                <h4>Disclaimer</h4>
                <p>
                    𝗥𝗨𝗣𝗞𝗔𝗧𝗛𝗔 always attempts to keep updated information on a product and its
                    attributes. Hardly, there can be any information on website, social media sites
                    that may contain typographical errors. 𝗥𝗨𝗣𝗞𝗔𝗧𝗛𝗔 claims the authority to rectify
                    any errors, update or add any information at any time without any given notice.
                    Errors are unintentional and we are extremely sorry for your inconvenience.
                </p>
                <h4>Product Attributes</h4>
                <p>
                    𝗥𝗨𝗣𝗞𝗔𝗧𝗛𝗔 always puts the best effort to maintain the entire product attributes
                    such as color, fabric, size and fitting to be the same in what you see in the
                    website and purchase from us.
                </p>
                <h4>Pricing and Payment Policy</h4>
                <p>
                    Due to typographical or photographical mistakes, if there is any error in price
                    found on the product, 𝗥𝗨𝗣𝗞𝗔𝗧𝗛𝗔 will postpone all the placed orders of the
                    customer till the right price is being updated. The price of the products is
                    quoted in Bangladeshi Taka (BDT) and is subject to VAT as per the laws of
                    Bangladesh.
                </p>
                <h4>Easy Return & Exchange</h4>
                <p>
                    Your satisfaction is our priority. If you are not satisfied with your purchase
                    then we are here to resolve your concern/issue. We will be delighted to exchange
                    any item returned in a saleable condition, with its receipt and original
                    packing. Please note that due to your laptop Screen settings and photographic
                    lighting, the product color may vary. Moreover, all fabric pieces, or patches
                    included in the product, are mentioned in the product description on website. We
                    will only be accepting those products for exchange/return that either have
                    manufacturing issues, wrong sizes and the shipment of the wrong items. No extra
                    amount will be charge for the exchange of the same product, however exchange
                    will entirely be dependent on the availability of the product. Exchange with a
                    different product is also possible but the additional cost of the exchange
                    product will be paid by the customer.
                </p>
                <h4>Refund Policy</h4>
                <p>
                    We have no policy for refund, however in case of non-availability of the
                    product, or if the received item is in a flawed condition, a coupon of the same
                    amount will be issued by online team.
                </p>
                <h4>Color Incompatibility</h4>
                <p>
                    Sometimes for professional photo-shoot, product colors may look different.
                    Please check our website for a better product view. We cannot guarantee all
                    these attributes, especially the color to be the same (In the website and
                    in-store) due to display differences of customer's used technology such as
                    mobile/tablet/computer/laptop etc.
                </p>
                <h4>Order policy:</h4>
                <p>
                    • No monetary refund is acceptable.
                    <br /> • Paid orders cannot be canceled or refunded for Online Payments. In such
                    situations, we provide customer-based solutions.
                    <br /> • Delivery orders from the Website or Facebook will be processed after
                    getting confirmation from our Customer Care representative
                    <br /> • Due to system malfunction, technical error in information or limited
                    stock issue may arise. In that case 𝗥𝗨𝗣𝗞𝗔𝗧𝗛𝗔 own the
                    <br />
                    authority to cancel the order. We apologize for any disturbance that may occur.
                    <br /> • In some unavoidable circumstances, 𝗥𝗨𝗣𝗞𝗔𝗧𝗛𝗔 may ask for pre-advance to
                    provide smooth delivery.
                </p>
                <h4>Customer Care Number for General Query: 01890307941</h4>
                <h4>For Order Related Query: </h4>
                <p>
                    : 01890307941 (Both numbers are always available except Friday and other public
                    holidays)
                </p>
                <h4>Shipping Policy:</h4>
                <p>
                    𝗥𝗨𝗣𝗞𝗔𝗧𝗛𝗔 always prioritizes customers' fulfillment based on their needs and
                    demand therefore we want to make sure that the given orders are secured and
                    ensure you the fastest possible delivery into your doorstep. After your order
                    has been placed to our website, you will get a call for reconfirmation from our
                    team.
                    <br /> If any misinformation occurs, our hotline numbers are always available to
                    reach.
                    <br />
                    <br /> ·Inside Dhaka delivery fee and delivery: Delivery fee 70 taka and
                    Delivery within 3 business days <br />
                    ·Outside Dhaka delivery fee and delivery: Delivery fee 150 taka and Delivery
                    within 5 business days
                </p>
                <h4>Availability:</h4>
                <p>
                    Our featured items are made with special care and they are handcrafted,
                    therefore they are in limited quantities. Because of their limited availability
                    stocks are not assured. When an item featured on www.rupkatha.com.bd is no
                    longer in stock, we make every attempt to remove that item from the website in a
                    timely manner.
                </p>
                <h5> “Discount products cannot be returned or exchanged”</h5>
            </Container>
        </section>
    );
}

export default TermsSection;
