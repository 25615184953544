import React from 'react';
import HomeIcon from '../../assets/images/IconAddressHome.svg';
import WorkIcon from '../../assets/images/IconAddressWork.svg';

function AddressTypeBtn({ type = '1' }) {
    if (type === '2') {
        return (
            <button
                type="button"
                className="address-type-btn"
                style={{ background: 'linear-gradient(154.38deg, #FF8BBC 2.32%, #FF28C3 83.79%))' }}
            >
                <img src={WorkIcon} alt="" />
                Office
            </button>
        );
    }
    return (
        <button type="button" className="address-type-btn">
            <img src={HomeIcon} alt="" />
            Home
        </button>
    );
}

export default AddressTypeBtn;
