import React, { createContext, useContext, useEffect, useRef, useState } from 'react';

const defaultTimeOut = 80000;
const TemplateContext = createContext();

export function useTemplate() {
    return useContext(TemplateContext);
}

const MessageTemplate = ({ message, setMessage }) => {
    const handleClose = () => {
        setMessage(null);
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setMessage(null);
        }, message?.timeout || defaultTimeOut);

        return () => {
            clearTimeout(timer);
        };
    }, [message, setMessage]);

    if (!message) {
        return null;
    }

    return (
        <div className="floating-message line line-no-wrap">
            <span className="cell cell-fill cell-self-middle">{message?.text || message}</span>
            <button type="button" onClick={handleClose} className="cell cell-self-middle">
                <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fal"
                    data-icon="times"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                >
                    <path
                        fill="currentColor"
                        d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
                    />
                </svg>
            </button>
        </div>
    );
};

function ConfirmTemplate({ confirm, setConfirm }) {
    const ref = useRef();

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setConfirm(null);
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setConfirm]);

    if (!confirm) {
        return null;
    }

    return (
        <div className="alert-backdrop">
            <div className="alert-confirm" ref={ref}>
                <div className="body">
                    <div className="icon">
                        {confirm.icon || (
                            <svg
                                width="49"
                                height="49"
                                viewBox="0 0 49 49"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle cx="24.5" cy="24.5" r="24" stroke="#FFA41D" />
                                <path
                                    d="M26.9659 18.275H26.9676L25.5534 27.7142H25.5516C25.4673 28.3578 25.2351 28.846 24.4998 28.846C23.7645 28.846 23.5324 28.3578 23.448 27.7142H23.4462L22.0321 18.275H22.0337C22.0123 18.1109 22 17.9421 22 17.7692C22 16.2398 22.8414 15 24.5 15C26.1584 15 27 16.2398 27 17.7692C26.9996 17.9423 26.9874 18.1109 26.9659 18.275ZM24.4996 30.6923C23.2097 30.6923 22.5552 31.6565 22.5552 32.8462C22.5552 34.0358 23.2097 35 24.4996 35C25.7896 35 26.4441 34.0358 26.4441 32.8462C26.4441 31.6565 25.7896 30.6923 24.4996 30.6923Z"
                                    fill="#FFA41D"
                                />
                            </svg>
                        )}
                    </div>
                    <div className="text">
                        <span>{confirm?.text}</span>
                    </div>
                    <div className="button-area">
                        <button
                            type="button"
                            className="button"
                            onClick={() => {
                                setConfirm(null);
                            }}
                        >
                            {confirm.textCancel || 'Cancel'}
                        </button>

                        <button
                            type="button"
                            className="button delete"
                            onClick={() => {
                                if (typeof confirm.clickAction === 'function') {
                                    confirm.clickAction();
                                }
                            }}
                        >
                            {confirm.txtAction || 'Delete'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

function TemplateProvider({ children }) {
    const [message, setMessage] = useState(null);
    const [confirm, setConfirm] = useState(null);

    const value = {
        message,
        setMessage,
        confirm,
        setConfirm,
    };

    return (
        <TemplateContext.Provider value={value}>
            {children}
            <MessageTemplate message={message} setMessage={setMessage} />
            <ConfirmTemplate confirm={confirm} setConfirm={setConfirm} />
        </TemplateContext.Provider>
    );
}

export default TemplateProvider;
