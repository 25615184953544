import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ConfigApi from '../../../../configs/ConfigApi';
import { useAuth } from '../../../contexts/AuthProvider';
import AxiosAuth from '../../../utils/AxiosAuth';
import FindInArray from '../../../utils/FindInArray';
import InitInfo from '../../../utils/InitInfo';
import PageProperties from '../../../utils/PageProperties';
import ReactGA4 from '../../../utils/ReactGA4';
import { ReviewStar } from '../modals/ModelReviews';

function UserReviewDetailPageComplete() {
    const { products } = InitInfo();
    const [reviewData, setReviewData] = useState('');
    const { reviewId } = useParams();
    const { currentUser } = useAuth();

    useEffect(() => {
        // Collect API Data
        if (reviewId) {
            AxiosAuth.currentUserAuth(currentUser)
                .get(ConfigApi.API_USER_REVIEW_DETAIL.replace(':reviewId', reviewId))
                .then((response) => {
                    setReviewData(response.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }

        // Collect invoice data
    }, [currentUser, reviewId]);

    const FindArr = new FindInArray();
    FindArr.init(products, 'id');
    const ItemProducts = FindArr.find(reviewData.productId);
    PageProperties('Reviews');

    return (
        <div className="col-12 col-lg-9">
            <ReactGA4 />
            <div className="user-review-detail">
                <div className="row">
                    <div className="col-12 col-lg-7 col-xl-7 col-md-6">
                        <div className="user-review-detail-title">Delivered on 09 Feb 2022</div>
                        <div className="user-review-detail-info">
                            <div className="row">
                                <div className="col-2">
                                    <img
                                        src={`https://www.optimizee.xyz/images/${btoa(
                                            ItemProducts?.default_image
                                        )},200,300.jpg`}
                                        alt=""
                                    />
                                </div>
                                <div className="col-9">
                                    <h6>{ItemProducts?.title}</h6>
                                    <div className="review-ratting">
                                        <ReviewStar currentReview={reviewData?.review_point} />
                                    </div>
                                    <div className="review-text-box">
                                        <lable>
                                            Review detail<span>How to write a good review?</span>
                                        </lable>
                                        <textarea
                                            type="text"
                                            value={reviewData?.comment}
                                            placeholder="Review detail"
                                            readOnly
                                        />
                                    </div>
                                    <div className="review-img-box">
                                        {reviewData?.images?.map((row) => (
                                            <img src={row} alt="" key={row} />
                                        ))}
                                    </div>
                                    <div className="review-notice-box">
                                        <p>Important:</p>
                                        <ul>
                                            <li>Maximum 6 images can be uploaded</li>
                                            <li>Image size can be maximum 5mb</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-1">x2</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-5 col-xl-5 col-md-6">
                        <div className="user-review-detail-title">Rate your Rider:</div>
                        <div className="user-review-detail-info">
                            <div className="review-ratting">
                                <ReviewStar currentReview={reviewData?.rider_review_point} />
                            </div>
                            <div className="review-text-box">
                                <lable>Review detail</lable>
                                <textarea type="text" value={reviewData?.rider_review_comment}>
                                    Review detail
                                </textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserReviewDetailPageComplete;
