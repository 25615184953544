import React, { useEffect, useState } from 'react';
import ConfigApi from '../../../../configs/ConfigApi';
import { useAuth } from '../../../contexts/AuthProvider';
import AxiosAuth from '../../../utils/AxiosAuth';
import PageProperties from '../../../utils/PageProperties';
import ReactGA4 from '../../../utils/ReactGA4';
import UserSupportAddModal from '../components/UserSupportAddModal';
import UserSupportSection from '../components/UserSupportSection';

const getSupportData = (params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.currentUserAuth(params.currentUser)
            .get(ConfigApi.API_SUPPORT)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

function UserSupportPage() {
    const [supportDepartment, setSupportDepartment] = useState([]);
    const [supportData, setSupportData] = useState([]);
    const [show, setShow] = useState(false);
    const { currentUser } = useAuth();

    useEffect(() => {
        getSupportData({ currentUser })
            .then((response) => {
                setSupportDepartment(response.data.supportDepartment);
                setSupportData(response.data.supportAr);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [currentUser, show]);
    PageProperties('Support');

    return (
        <div className="col-12 col-lg-9">
            <ReactGA4 />
            <UserSupportSection
                supportData={supportData}
                supportDepartment={supportDepartment}
                setShow={setShow}
            />
            <UserSupportAddModal
                supportDepartment={supportDepartment}
                show={show}
                setShow={setShow}
            />
        </div>
    );
}

export default UserSupportPage;
