import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import SwipeableViews from 'react-swipeable-views';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import EffectOne from '../../../utils/effects/EffectOne';
import FindInArray from '../../../utils/FindInArray';
import InitInfo from '../../../utils/InitInfo';

const styles = {
    root: {
        padding: '0 30px',
        margin: '0 -15px',
    },
    slideContainer: {
        padding: '0 10px',
    },
    slide: {
        minHeight: 100,
        color: '#fff',
    },
};

// function FeatureProduct({ data }) {
//     return (
//         <div className="col-12 col-lg-4 feature-product">
//             <EffectOne data={data} />
//         </div>
//     );
// }

function DesktopFeature({
    products,
    featureCategoryOptions,
    index,
    countImage,
    handleChangeIndex,
}) {
    const FindArr = new FindInArray();
    FindArr.init(products, 'id');
    return (
        <div className="col-12 feature-product">
            {featureCategoryOptions && (
                <SwipeableViews
                    className={[
                        'position-set',
                        index === 0 ? 'start-set' : '',
                        countImage - index === 1 ? 'end-set' : '',
                    ].join(' ')}
                    style={styles.root}
                    slideStyle={styles.slideContainer}
                    onChangeIndex={handleChangeIndex}
                >
                    {featureCategoryOptions &&
                        featureCategoryOptions.map((feature) => (
                            <div key={`${feature.id}`}>
                                <EffectOne data={feature} />
                            </div>
                        ))}
                </SwipeableViews>
            )}
        </div>
    );
}
function MobileFeature({ products, featureCategoryOptions, index, countImage, handleChangeIndex }) {
    const FindArr = new FindInArray();
    FindArr.init(products, 'id');

    return (
        <div className="m-feature-show">
            {featureCategoryOptions && (
                <SwipeableViews
                    className={[
                        'position-set',
                        index === 0 ? 'start-set' : '',
                        countImage - index === 1 ? 'end-set' : '',
                    ].join(' ')}
                    style={styles.root}
                    slideStyle={styles.slideContainer}
                    onChangeIndex={handleChangeIndex}
                >
                    {featureCategoryOptions &&
                        featureCategoryOptions.map((feature) => (
                            <div style={{ ...styles.slide }} key={`${feature.id}`}>
                                <EffectOne data={feature} />
                            </div>
                        ))}
                </SwipeableViews>
            )}
        </div>
    );
}

function FeatureProductSection() {
    const { products, featureCategoryOptions } = InitInfo();

    const [index, setIndex] = useState(0);
    const countImage = featureCategoryOptions ? featureCategoryOptions.length : 3;

    const handleChangeIndex = (ind) => {
        setIndex(ind);
    };

    const FindArr = new FindInArray();
    FindArr.init(products, 'id');

    return (
        <section className="mb-3" style={{ paddingTop: '20px' }}>
            <Container style={{ paddingBottom: '10px' }}>
                <MobileFeature
                    featureCategoryOptions={featureCategoryOptions}
                    index={index}
                    countImage={countImage}
                    handleChangeIndex={handleChangeIndex}
                    products={products}
                />
                <div className="row m-feature-hide">
                    {/* {featureCategoryOptions &&
                        featureCategoryOptions.map((feature) => {
                            const itemData = FindArr.find(feature.product_sl.toString());
                            return <FeatureProduct key={`${feature.product_sl}`} data={itemData} />;
                        })} */}
                    <DesktopFeature
                        featureCategoryOptions={featureCategoryOptions}
                        index="3"
                        countImage={countImage}
                        handleChangeIndex={handleChangeIndex}
                        products={products}
                    />
                </div>
            </Container>
        </section>
    );
}

export default FeatureProductSection;
