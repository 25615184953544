import React from 'react';
import { Container } from 'react-bootstrap';
import ProductItem from '../../products/components/ProductItem';

function SelectedProductSection({ products }) {
    return (
        <section className="product-section">
            <Container>
                <h4 className="custom-typo">
                    <span>Products</span>
                </h4>
                <div className="row row-m-product">
                    {products &&
                        products.slice(0, 10).map((item) => (
                            <div
                                className="col mb-4 col-m-product product-section-colI5"
                                key={item.id}
                            >
                                <ProductItem itemData={item} />
                            </div>
                        ))}
                </div>
            </Container>
        </section>
    );
}

export default SelectedProductSection;
