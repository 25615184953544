import React from 'react';
import {
    Button,
    Card,
    Container,
    // eslint-disable-next-line prettier/prettier
    Table
} from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import NoProductImg from '../../../../assets/images/NoProduct2.png';
import ConfigApi from '../../../../configs/ConfigApi';
import AddressProvider, { useAddress } from '../../../contexts/AddressProvider';
import { useAuth } from '../../../contexts/AuthProvider';
import { useCart } from '../../../contexts/CartProvider';
import { useTemplate } from '../../../contexts/TemplateProvider';
import AddressTypeBtn from '../../../utils/AddressTypeBtn';
import AxiosAuth from '../../../utils/AxiosAuth';
import FindInArray from '../../../utils/FindInArray';

function ProductSize({ size }) {
    return (
        <button type="button" className="cart-detail-sizeBtn">
            {size}
        </button>
    );
}

function ProductAgeRange({ age }) {
    return (
        <button type="button" className="cart-detail-ageBtn">
            {age}
        </button>
    );
}

function ProductColor({ color }) {
    return (
        <button
            type="button"
            style={{
                backgroundColor: color,
            }}
            className="cart-color-item-bg"
        />
    );
}

function ProductVariantOption({ itemName }) {
    return (
        <>
            {itemName.color ? <ProductColor color={itemName.color} /> : ''}
            {itemName.size ? <ProductSize size={itemName.size} /> : ''}
            {itemName.ageRange ? <ProductAgeRange age={itemName.ageRange} /> : ''}
        </>
    );
}

function CheckoutProductVariant({ data }) {
    if (data) {
        return <ProductVariantOption itemName={data} />;
    }

    return null;
}

function CheckoutProduct({ myItem: item, variation }) {
    if (variation.quantity < 1 || !variation.isSelected) {
        return null;
    }

    return (
        <div className="cart-product-list row">
            <div className="col-2 cart-product-flex">
                <div className="cart-section-product-img">
                    <img src={item.default_image} alt="" />
                </div>
            </div>

            <div className="col-5 cart-product-flex" style={{ textAlign: 'left' }}>
                <Link to={`/products/${item.id}/${item.title.replace(/[^\w\\s]/gi, '-')}`}>
                    <b>{item.title}</b>
                </Link>
                <br />
                <div className="variant-list-cart">
                    <CheckoutProductVariant data={variation} />
                </div>
            </div>

            <div className="col-3 cart-product-flex cart-qty">
                <small>
                    {item.priceOffer.toFixed(2)} x {variation.quantity}
                </small>
            </div>

            <div className="col-2 cart-product-flex">
                {(variation.quantity * item.priceOffer).toFixed(2)}
            </div>
        </div>
    );
}

function CheckoutData({ cartItems, products }) {
    const FindArr = new FindInArray();
    FindArr.init(products, 'id');

    return (
        <div className="cart-section-product-list">
            {cartItems.map((cartItem) => {
                const itemData = FindArr.find(cartItem.productId);
                const uKey = cartItem.hash || Math.random();

                return (
                    <CheckoutProduct
                        myItem={itemData}
                        key={`${cartItem.productId}-${uKey}`}
                        quantity={cartItem.quantity}
                        variation={cartItem.productVariation}
                    />
                );
            })}
        </div>
    );
}

function CheckoutProducts({ cartItems, products }) {
    return (
        <div className="col-12 col-lg-8 mb-4">
            <Card className="cart-section-card">
                <Card.Body>
                    <div className="cart-section-product">
                        <div className="cart-section-product-top">
                            <h6>Checkout Products</h6>
                        </div>
                        {cartItems.length !== 0 ? (
                            <CheckoutData products={products} cartItems={cartItems} />
                        ) : (
                            <div className="order-section-no-order mt-4">
                                <div className="order-section-no-order-title">
                                    <h5>There Are No Checkout Products.</h5>
                                    <h6>You can see Checkout products here.</h6>
                                </div>
                                <div className="order-section-no-order-img">
                                    <img src={NoProductImg} alt="No Product" />
                                </div>
                            </div>
                        )}
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}

function CheckoutAddress({ selectedAddress, setShowAddressManager, setSelectedAddress }) {
    return (
        <>
            <div className="checkout-summary-top">
                <p>{selectedAddress?.name}</p>
                <button
                    type="button"
                    onClick={() => {
                        setShowAddressManager('list');
                    }}
                >
                    Change
                </button>
            </div>
            <div className="checkout-summary-address">
                <AddressTypeBtn type={selectedAddress?.resident_type} />
                <p>
                    {[selectedAddress?.address?.line1, selectedAddress?.address?.line2]
                        .filter((item) => !!item)
                        .join(', ')}
                    <br />
                    {[
                        selectedAddress?.address?.city,
                        selectedAddress?.address?.state,
                        selectedAddress?.address?.country,
                        selectedAddress?.address?.zip,
                    ]
                        .filter((item) => !!item)
                        .join(', ')}
                    <br />
                    <span>{selectedAddress?.phone || 'Phone not set'}</span>,{' '}
                    {selectedAddress?.email || (
                        <button
                            type="button"
                            className="btn-set-email"
                            onClick={() => {
                                setShowAddressManager('update');
                            }}
                        >
                            Email not set
                        </button>
                    )}
                    <br />
                </p>
            </div>
            <div className="checkout-summary-top">
                <p>Bill to the same address</p>
            </div>
            <div className="checkout-summary-top">
                <p>{selectedAddress?.phone}</p>
                <button
                    type="button"
                    onClick={() => {
                        setShowAddressManager('update');
                        setSelectedAddress(selectedAddress);
                    }}
                >
                    Edit
                </button>
            </div>
            <div className="checkout-summary-top">
                <p>{selectedAddress?.email}</p>
                <button
                    type="button"
                    onClick={() => {
                        setShowAddressManager('update');
                        setSelectedAddress(selectedAddress);
                    }}
                >
                    Edit
                </button>
            </div>
        </>
    );
}

function CheckoutUser({ currentUser }) {
    const { setShowAddressManager } = useAddress();
    return (
        <>
            <div className="checkout-summary-top">
                <p style={{ width: '50%' }}>{currentUser?.displayName}</p>
                <button
                    style={{ width: '50%' }}
                    type="button"
                    onClick={() => {
                        setShowAddressManager('add');
                    }}
                >
                    + Add Address
                </button>
            </div>
            <div className="checkout-summary-address">
                <div className="checkout-summary-top" style={{ width: '100%' }}>
                    <p>Bill to the same address</p>
                </div>
                {currentUser?.phoneNumber ? (
                    <div className="checkout-summary-top" style={{ width: '100%' }}>
                        <p>{currentUser?.phoneNumber}</p>
                    </div>
                ) : null}
                {currentUser?.email ? (
                    <div className="checkout-summary-top" style={{ width: '100%' }}>
                        <p>{currentUser?.email}</p>
                    </div>
                ) : null}
            </div>
        </>
    );
}

function CheckoutSummerySection({ totalItem, totalPrice, setCartItems, shippingCharge }) {
    const { setMessage } = useTemplate();
    const { currentUser } = useAuth();
    const { selectedAddress, setShowAddressManager, setSelectedAddress } = useAddress();
    const navigate = useNavigate();

    const handleCreateInvoice = () => {
        // Address ID

        setMessage('Creating Invoice...');
        AxiosAuth.currentUserAuth(currentUser)
            .post(ConfigApi.API_INVOICE_CREATE, {
                shippingAddressId: selectedAddress?.sl,
                billingAddressId: selectedAddress?.sl,
            })
            .then(({ data }) => {
                setMessage(data.message);
                if (data.error === 0) {
                    setCartItems(data.cartItems);
                    navigate(`/invoice/${data.invoiceId}/payment/`);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <div className="col-12 col-lg-4">
            <Card>
                <Card.Body>
                    <div className="checkout-summary-title"> Shipping & Billing</div>
                    {selectedAddress ? (
                        <CheckoutAddress
                            selectedAddress={selectedAddress}
                            setShowAddressManager={setShowAddressManager}
                            setSelectedAddress={setSelectedAddress}
                        />
                    ) : (
                        <CheckoutUser currentUser={currentUser} />
                    )}

                    <div className="checkout-summary-divider" />
                    <div className="checkout-summary-title"> Order Summary</div>
                    <div className="cart-section-summary">
                        <Table>
                            <tbody>
                                <tr>
                                    <td>Subtotal ({totalItem} items)</td>
                                    <td className="text-right">৳ {totalPrice.toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td>Shipping Fee</td>
                                    <td className="text-right">৳ {shippingCharge.toFixed(2)}</td>
                                </tr>
                                <tr style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                    <td>Total</td>
                                    <td className="text-right">
                                        ৳ {(totalPrice + shippingCharge).toFixed(2)}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>

                        {!selectedAddress ? (
                            <span className="checkout-summary-note">
                                <li>Please Add Shipping Address</li>
                            </span>
                        ) : null}

                        <Button
                            className="proceed-to-pay"
                            onClick={handleCreateInvoice}
                            disabled={!selectedAddress}
                        >
                            Confirm Order
                        </Button>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}

function CheckoutPageSection({ products, addressData }) {
    const { currentUser } = useAuth();
    const { cartItems, subTotal, totalItem, setCartItems, shippingCharge } = useCart();

    return (
        <section className="cart-section">
            <Container>
                <div className="row">
                    <CheckoutProducts cartItems={cartItems} products={products} />

                    <AddressProvider currentUser={currentUser} addressData={addressData} isBackIcon>
                        <CheckoutSummerySection
                            totalPrice={subTotal}
                            totalItem={totalItem}
                            setCartItems={setCartItems}
                            shippingCharge={shippingCharge}
                        />
                    </AddressProvider>
                </div>
            </Container>
        </section>
    );
}

export default CheckoutPageSection;
