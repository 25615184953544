/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import ConfigApi from '../../../../configs/ConfigApi';
import { useCart } from '../../../contexts/CartProvider';
import AddressTypeBtn from '../../../utils/AddressTypeBtn';
import AxiosAuth from '../../../utils/AxiosAuth';

// import UserAddressModal from '../modals/UserAddressModal';
function AddressTable({ data, handleChange, selectedValue }) {
    return (
        <tr style={{ fontSize: '12px' }}>
            <td style={{ paddingLeft: 19 }}>
                {data.name} <br />
                <AddressTypeBtn type={data.resident_type} />
            </td>
            <td>
                {data.address?.line1},{data.address?.line2},{data.address?.city},
                {data.address?.state},<br />
                {data.address?.country},{data.address?.zip}
            </td>
            <td>
                {data.phone}
                <br />
                {data.email}
            </td>

            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                <Form.Check
                    checked={selectedValue?.sl === data?.sl}
                    onChange={() => {
                        handleChange(data);
                    }}
                    value={data.sl}
                    name="radio-button-demo"
                    type="radio"
                    className="user-address-radio-check"
                />
            </td>
        </tr>
    );
}

function UserAddressListModal({
    show,
    setShowAddressManager,
    addresses,
    selectedAddress,
    setSelectedAddress,
}) {
    const [addModal, setAddModal] = useState(false);
    const { forceUpdateCart } = useCart();

    const handleChange = (item) => {
        AxiosAuth.post(ConfigApi.API_USER_ADDRESS_DEFAULT_UPDATE.replace(':addressId', item.sl))
            .then((response) => {
                if (response.data.error === 0) {
                    setSelectedAddress(item);
                    setShowAddressManager(null);
                    forceUpdateCart();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <Modal
            size="lg"
            show={show}
            onHide={() => setShowAddressManager(false)}
            aria-labelledby="example-modal-sizes-title-lg"
            contentClassName="address-modal-content btn-close-custom"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Address{' '}
                    <button
                        type="button"
                        onClick={() => setShowAddressManager('add')}
                        className="address-back-btn"
                    >
                        Add New Address
                    </button>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <table className="address-modal-table">
                    <thead>
                        <tr style={{ fontSize: '12px', fontWeight: 'bold' }}>
                            <th style={{ paddingLeft: 19 }}>Full Name</th>
                            <th>Address</th>
                            <th>Contact</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {addresses?.map((item) => (
                            <AddressTable
                                data={item}
                                // setAddModal={pSetAddModal}
                                key={item.sl}
                                selectedValue={selectedAddress}
                                handleChange={handleChange}
                            />
                        ))}
                    </tbody>
                </table>
            </Modal.Body>
        </Modal>
    );
}

export default UserAddressListModal;
