import React, { useEffect, useRef, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useTemplate } from '../../../contexts/TemplateProvider';
import ListCountry from '../../../utils/ListCountry';

function UserAddressAddModal({
    addAddress,
    updateAddress,
    selectedAddress,
    setShowAddressManager,
    isBackIcon,
}) {
    const addressId = selectedAddress.sl;
    const [addressInfo] = useState(selectedAddress);
    const { setMessage } = useTemplate();

    const refName = useRef();
    const refPhone = useRef();
    const refEmail = useRef();
    const refLine1 = useRef();
    const refLine2 = useRef();
    const refState = useRef();
    const refCity = useRef();
    const refZip = useRef();
    const refType = useRef();
    const refCountry = useRef();

    const handleSubmitCreate = () => {
        setMessage('Loading...');

        addAddress({
            name: refName.current.value,
            phone: refPhone.current.value,
            email: refEmail.current.value,
            line1: refLine1.current.value,
            line2: refLine2.current.value,
            country: refCountry.current.value,
            state: refState.current.value,
            city: refCity.current.value,
            zipCode: refZip.current.value,
            selectedType: refType.current.querySelector('input:checked')?.value || '',
        })
            .then(({ message, error }) => {
                setMessage(message);

                if (error === 0) {
                    //--
                    setShowAddressManager(null);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleSubmitUpdate = () => {
        setMessage('Loading...');

        updateAddress({
            addressId,
            name: refName.current.value,
            phone: refPhone.current.value,
            email: refEmail.current.value,
            line1: refLine1.current.value,
            line2: refLine2.current.value,
            country: refCountry.current.value,
            state: refState.current.value,
            city: refCity.current.value,
            zipCode: refZip.current.value,
            selectedType: refType.current.querySelector('input:checked')?.value || '',
        })
            .then(({ message, error }) => {
                setMessage(message);

                if (error === 0) {
                    //--
                    setShowAddressManager(null);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handleChangeCountry = (ev) => {
        refCountry.current.value = ev.target.value;
    };
    // --Update Existing Value
    useEffect(() => {
        if (addressInfo) {
            refName.current.value = addressInfo?.name || '';
            refPhone.current.value = addressInfo?.phone || '';
            refEmail.current.value = addressInfo?.email || '';
            refLine1.current.value = addressInfo?.address?.line1 || '';
            refLine2.current.value = addressInfo?.address?.line2 || '';
            refState.current.value = addressInfo?.address?.state || '';
            refCity.current.value = addressInfo?.address?.city || '';
            refZip.current.value = addressInfo?.address?.zip || '';
            refCountry.current.value = addressInfo?.address?.country || 'BD';
            const radioBtn = refType.current.querySelector(
                `input[value*='${addressInfo?.resident_type || 1}']`
            );
            if (radioBtn) {
                radioBtn.checked = true;
            }
        }
    }, [addressInfo]);

    return (
        <Modal
            size="lg"
            show
            onHide={() => {
                setShowAddressManager(null);
            }}
            aria-labelledby="example-modal-sizes-title-lg"
            contentClassName="address-modal-content btn-close-custom"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {!addressId ? 'Add New Address' : 'Update Address'}{' '}
                    {isBackIcon ? (
                        <button
                            type="button"
                            onClick={() => setShowAddressManager('list')}
                            className="address-back-btn"
                        >
                            Go to list
                        </button>
                    ) : (
                        ''
                    )}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form className="row">
                    <div className="col-12 col-lg-6">
                        <Form.Group className="custom-login-input">
                            <Form.Label className="custom-label">Full Name</Form.Label>
                            <input
                                ref={refName}
                                className="form-control"
                                type="text"
                                placeholder="Enter Full Name"
                            />
                        </Form.Group>
                        <Form.Group className="custom-login-input">
                            <Form.Label className="custom-label">Phone Number</Form.Label>
                            <input
                                ref={refPhone}
                                className="form-control"
                                type="text"
                                placeholder="Enter Phone Number"
                            />
                        </Form.Group>
                        <Form.Group className="custom-login-input">
                            <Form.Label className="custom-label">
                                Email <small>(Optional)</small>
                            </Form.Label>
                            <input
                                ref={refEmail}
                                className="form-control"
                                type="email"
                                placeholder="Enter Email"
                            />
                        </Form.Group>
                        <Form.Group className="custom-login-input">
                            <Form.Label className="custom-label">Country</Form.Label>
                            <ListCountry def={refCountry} handleChange={handleChangeCountry} />
                        </Form.Group>
                    </div>
                    <div className="col-12 col-lg-6">
                        <Form.Group className="custom-login-input">
                            <Form.Label className="custom-label">Region/State</Form.Label>
                            <input
                                ref={refState}
                                className="form-control"
                                type="text"
                                placeholder="Enter Region/State"
                            />
                        </Form.Group>
                        <Form.Group className="custom-login-input">
                            <Form.Label className="custom-label">City</Form.Label>
                            <input
                                ref={refCity}
                                className="form-control"
                                type="text"
                                placeholder="Enter City"
                            />
                        </Form.Group>
                        <Form.Group className="custom-login-input">
                            <Form.Label className="custom-label">Area</Form.Label>
                            <input
                                ref={refLine1}
                                className="form-control"
                                type="text"
                                placeholder="Enter Area"
                            />
                        </Form.Group>
                        <Form.Group className="custom-login-input">
                            <Form.Label className="custom-label">Address</Form.Label>
                            <textarea
                                ref={refLine2}
                                className="form-control"
                                type="text"
                                placeholder="Enter Address"
                            />
                        </Form.Group>

                        <Form.Group className="custom-login-input">
                            <Form.Label className="custom-label">
                                Zip Code <small>(Optional)</small>
                            </Form.Label>
                            <input
                                ref={refZip}
                                className="form-control"
                                type="text"
                                placeholder="Enter Zip Code"
                            />
                        </Form.Group>

                        <Form.Group
                            ref={refType}
                            aria-label="address type"
                            className="custom-login-input"
                        >
                            <Form.Check value="1" type="radio" label="Home" name="address_type" />
                            <Form.Check value="2" type="radio" label="Office" name="address_type" />
                        </Form.Group>

                        <button
                            type="button"
                            style={{ float: 'right' }}
                            className="address-submit-btn"
                            onClick={addressId ? handleSubmitUpdate : handleSubmitCreate}
                        >
                            Save Info
                        </button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default UserAddressAddModal;
