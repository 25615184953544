import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useAuth } from '../contexts/AuthProvider';

function ReactGA4() {
    const { currentUser } = useAuth();
    const userId = currentUser?.userId || null;
    const loaded = currentUser?.loaded || false;

    useEffect(() => {
        if (loaded) {
            ReactGA.initialize([
                {
                    trackingId: 'G-Q9C2QWCKSJ',
                    gaOptions: {
                        name: 'rupkathaWebTracker',
                        clientId: userId,
                        alwaysSendReferrer: true,
                    }, // optional
                    // gtagOptions: { ...}, // optional
                },
            ]);
            ReactGA.send({ hitType: 'pageview', page: window.location.href });
        }
    }, [userId, loaded]);

    return null;
}

export default ReactGA4;
