import React from 'react';
import { Provider } from 'react-redux';
import '../assets/style.all.css';
import AuthProvider from './contexts/AuthProvider';
import CartProvider from './contexts/CartProvider';
import TemplateProvider from './contexts/TemplateProvider';
import RouteHandler from './RouteHandler';
import store from './store';

function App() {
    return (
        <TemplateProvider>
            <AuthProvider>
                <CartProvider>
                    <Provider store={store}>
                        <RouteHandler />
                    </Provider>
                </CartProvider>
            </AuthProvider>
        </TemplateProvider>
    );
}

export default App;
