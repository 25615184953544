import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export function ReviewStar({ currentReview, setCurrentReview }) {
    const rows = [];
    for (let i = 1; i <= 5; i += 1) {
        if (i <= currentReview) {
            rows.push(
                <FontAwesomeIcon
                    size="lg"
                    icon={faStar}
                    color="#FFC01D"
                    onClick={() => setCurrentReview(i)}
                    key={i}
                />
            );
        } else {
            rows.push(
                <FontAwesomeIcon
                    size="lg"
                    icon={faStar}
                    color="#CFD1D5"
                    onClick={() => setCurrentReview(i)}
                    key={i}
                />
            );
        }
    }
    return rows;
}

export function ReviewStar2({ currentReview, setCurrentReview }) {
    const rows = [];
    for (let i = 1; i <= 5; i += 1) {
        if (i <= currentReview) {
            rows.push(
                <FontAwesomeIcon
                    size="lg"
                    icon={faStar}
                    color="#FFC01D"
                    onClick={() => setCurrentReview(i)}
                    key={i}
                />
            );
        } else {
            rows.push(
                <FontAwesomeIcon
                    size="lg"
                    icon={faStar}
                    color="#CFD1D5"
                    onClick={() => setCurrentReview(i)}
                    key={i}
                />
            );
        }
    }
    return rows;
}

export function ReviewStar3({ currentReview }) {
    const rows = [];
    for (let i = 1; i <= 5; i += 1) {
        if (i <= currentReview) {
            rows.push(<FontAwesomeIcon size="sm" icon={faStar} color="#FFC01D" key={i} />);
        } else {
            rows.push(<FontAwesomeIcon size="sm" icon={faStar} color="#CFD1D5" key={i} />);
        }
    }
    return rows;
}

function ModelReviews() {
    return null;
}

export default ModelReviews;
