/* eslint-disable react/no-unknown-property */
import React, { forwardRef, useEffect, useState } from 'react';
import ConfigApi from '../../configs/ConfigApi';
import { useAuth } from '../contexts/AuthProvider';
import InitInfo from '../utils/InitInfo';

const sslComConf = {
    development: {
        url: `https://sandbox.sslcommerz.com/embed.min.js?${Math.random()
            .toString(36)
            .substring(7)}`,
    },
    production: {
        url: `https://seamless-epay.sslcommerz.com/embed.min.js?${Math.random()
            .toString(36)
            .substring(7)}`,
    },
};

function InitSslcom(isProduction, loaded, setLoaded) {
    const url = isProduction ? sslComConf.production.url : sslComConf.development.url;

    if (!loaded) {
        const script = document.createElement('script');
        const tag = document.getElementsByTagName('script')[0];
        script.src = url;
        tag.parentNode.insertBefore(script, tag);
        setLoaded(true);
    }
}

const Sslcom = forwardRef((props, ref) => {
    const { order } = props; // , amount, currency
    const [loaded, setLoaded] = useState(false);
    const { currentUser } = useAuth();
    const { initId, shopId, isPaymentInProduction } = InitInfo();

    useEffect(() => {
        InitSslcom(isPaymentInProduction, loaded, setLoaded);

        const btn = document.querySelector('#sslczPayBtn');
        btn.postdata = {
            token: `${currentUser.localUid}:::${currentUser.refreshToken}:::${initId}:::${shopId}`,
            locale: localStorage.getItem('locale'),
        };
    }, [
        currentUser.localUid,
        currentUser.refreshToken,
        initId,
        isPaymentInProduction,
        loaded,
        shopId,
    ]);

    return (
        <button
            type="button"
            style={{ display: 'none' }}
            id="sslczPayBtn"
            ref={ref}
            order={order}
            endpoint={ConfigApi.SSLCOM_ENDPOINT}
        />
    );
});

export default Sslcom;
