/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-loop-func */
/* eslint-disable no-await-in-loop */

import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { createRef, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link } from 'react-router-dom';
import IconCross from '../../../../assets/images/IconCross.svg';
import ConfigApi from '../../../../configs/ConfigApi';
import { useAuth } from '../../../contexts/AuthProvider';
import AxiosAuth from '../../../utils/AxiosAuth';

function MessageShow({ ticketMsg, userInfo }) {
    return (
        <>
            {ticketMsg?.user_sl !== userInfo?.sl ? (
                <div className="incoming_msg">
                    <div className="incoming_msg_img">
                        <div className="profile-user-circle-chat">
                            <span>KB</span>
                        </div>
                    </div>
                    <div className="received_msg">
                        <div className="received_withd_msg">
                            {ticketMsg.file_url ? (
                                <img src={ticketMsg.file_url} alt={ticketMsg.subject} />
                            ) : (
                                <p>{ticketMsg.ticket_detail}</p>
                            )}

                            <span className="time_date">
                                {' '}
                                {ticketMsg.timeDate} | {ticketMsg.dayDate}
                            </span>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="outgoing_msg">
                    <div className="sent_msg">
                        {ticketMsg.file_url ? (
                            <img src={ticketMsg.file_url} alt={ticketMsg.subject} />
                        ) : (
                            <p>{ticketMsg.ticket_detail}</p>
                        )}
                        <span className="time_date">
                            {' '}
                            {ticketMsg.timeDate} | {ticketMsg.dayDate}
                        </span>{' '}
                    </div>
                </div>
            )}
        </>
    );
}
function OpenTicket({ supportData }) {
    return (
        <div className="inbox_chat">
            {supportData &&
                supportData.map((support) => (
                    <Link to={`/user/support/${support.id}/detail`} key={support.id}>
                        <div className="chat_list" key="support.id">
                            <div className="chat_people">
                                <div className="chat_ib">
                                    <h5>
                                        #{support.id}
                                        <span className="chat_date" style={{ fontSize: '11px' }}>
                                            {support.time_created}
                                        </span>
                                    </h5>
                                    <p>{support.subject}</p>
                                    <span>{support.ticket}</span>
                                </div>
                            </div>
                        </div>
                    </Link>
                ))}
        </div>
    );
}
export default function SupportDetailSection({
    supportData,
    supportTicketMessageData,
    sendMessage,
    message,
    handleMessage,
    handleOnFileChange,
    messageFile,
    handleRemoveFile,
    // setCompleteModalShow,
}) {
    const uploadRef = createRef();
    const { currentUser } = useAuth();
    const [userInfo, setUserInfo] = useState(null);

    useEffect(() => {
        AxiosAuth.currentUserAuth(currentUser)
            .get(ConfigApi.API_PROFILE_DATA)
            .then(({ data }) => {
                setUserInfo(data.userInfoAr);
            });
    }, [currentUser]);
    if (!userInfo) {
        return null;
    }

    return (
        <Card className="user-support-section">
            <Card.Body>
                <div className="user-support-title">
                    <h6>Support Chat </h6>
                </div>
                <div className="messaging">
                    <div className="inbox_msg">
                        <div className="inbox_people">
                            <div className="headind_srch">
                                <Tabs
                                    defaultActiveKey="profile"
                                    id="uncontrolled-tab-example"
                                    className="mb-3 user-support-section-sidebar-title"
                                >
                                    <Tab eventKey="home" title="Open Ticket">
                                        <OpenTicket supportData={supportData} />
                                    </Tab>
                                    <Tab eventKey="profile" title="Completed">
                                        <OpenTicket supportData={supportData} />
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                        <div className="mesgs">
                            <div className="msg_history">
                                {supportTicketMessageData &&
                                    supportTicketMessageData.map((ticketMsg) => (
                                        <MessageShow
                                            ticketMsg={ticketMsg}
                                            userInfo={userInfo}
                                            key={ticketMsg.id}
                                        />
                                    ))}
                            </div>
                            <div className="type_msg">
                                <div className="msg-input-group">
                                    <input
                                        type="text"
                                        className="write_msg"
                                        onChange={handleMessage}
                                        placeholder="Type a message"
                                        value={message}
                                        style={{ paddingLeft: messageFile ? 50 : 20 }}
                                    />

                                    {messageFile && (
                                        <div className="img-with-btn">
                                            <img src={messageFile} />
                                            <button
                                                type="button"
                                                className="btn-close"
                                                onClick={handleRemoveFile}
                                            >
                                                <img src={IconCross} alt="" style={{ width: 7 }} />
                                            </button>
                                        </div>
                                    )}
                                    <button
                                        type="button"
                                        className="msg-input-orderBtn"
                                        onClick={() => {
                                            uploadRef.current.click();
                                        }}
                                    />
                                    <button
                                        type="button"
                                        className="msg-input-attaBtn"
                                        onClick={() => {
                                            uploadRef.current.click();
                                        }}
                                    />
                                    <input
                                        ref={uploadRef}
                                        type="file"
                                        name="file"
                                        onChange={handleOnFileChange}
                                        multiple
                                        style={{ display: 'none' }}
                                        accept="image/png, image/jpeg,,application/pdf"
                                    />
                                    <button
                                        type="button"
                                        className="msg-input-sentBtn"
                                        onClick={sendMessage}
                                    >
                                        <FontAwesomeIcon
                                            icon={faPaperPlane}
                                            cursor="pointer"
                                            style={{
                                                color: 'white',
                                            }}
                                        />
                                    </button>
                                </div>
                            </div>
                            {/* <div className="col-1">
                                <button
                                    className="msg_send_btn"
                                    type="button"
                                    onClick={() => setCompleteModalShow(true)}
                                >
                                    <FontAwesomeIcon
                                        icon={faCheck}
                                        cursor="pointer"
                                        style={{
                                            color: 'white',
                                        }}
                                    />
                                </button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
}
