import React, { createContext, useContext, useRef, useState } from 'react';
import Bkash from '../gateways/Bkash';
import Cod from '../gateways/Cod';
import Sslcom from '../gateways/Sslcom';
import InitInfo from '../utils/InitInfo';

const PaymentContext = createContext();

export function usePayment() {
    return useContext(PaymentContext);
}

function PaymentProvider({ children, invoiceInfo }) {
    const [selectedPayment, setSelectedPayment] = useState(null);
    const { paymentOptions } = InitInfo();
    const selectedVendor = selectedPayment?.vendor;
    const bKashRef = useRef(null);
    const sslcomRef = useRef(null);
    const codRef = useRef(null);

    const paymentClickHandler = () => {
        if (selectedVendor === 'cod') {
            codRef.current.click();
        } else if (selectedVendor === 'bkash') {
            bKashRef.current.click();
        } else if (selectedVendor === 'sslcom') {
            sslcomRef.current.click();
        }
    };

    const value = {
        paymentOptions,
        paymentActiveOptions: paymentOptions.filter((item) => item.isEnable),
        selectedPayment,
        setSelectedPayment,
        paymentClickHandler,
    };

    return (
        <PaymentContext.Provider value={value}>
            {children}
            {selectedVendor === 'cod' ? (
                <Cod
                    ref={codRef}
                    order={invoiceInfo?.id || ''}
                    amount={invoiceInfo?.dueAmount || 0}
                    currency={invoiceInfo?.currency}
                />
            ) : null}
            {selectedVendor === 'bkash' ? (
                <Bkash
                    ref={bKashRef}
                    order={invoiceInfo?.id || ''}
                    amount={invoiceInfo?.dueAmount || 0}
                    currency={invoiceInfo?.currency}
                />
            ) : null}
            {selectedVendor === 'sslcom' ? (
                <Sslcom
                    ref={sslcomRef}
                    order={invoiceInfo?.id || ''}
                    amount={invoiceInfo?.dueAmount || 0}
                    currency={invoiceInfo?.currency}
                />
            ) : null}
        </PaymentContext.Provider>
    );
}

export default PaymentProvider;
