import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import ConfigApi from '../../../../configs/ConfigApi';
import { useAuth } from '../../../contexts/AuthProvider';
import { useTemplate } from '../../../contexts/TemplateProvider';
import AxiosAuth from '../../../utils/AxiosAuth';

function UserUpdateModals({ show, setShow, typeData, userInfo }) {
    const { currentUser } = useAuth();

    const [name, setName] = useState(userInfo.name);
    const [contact, setContact] = useState(userInfo?.default_contact);
    const { setMessage } = useTemplate();

    const handleValue = (ev) => {
        if (ev.target.name === 'name') {
            setName(ev.target.value);
        } else if (ev.target.name === 'contact') {
            setContact(ev.target.value);
        }
    };

    const handleProfileChange = () => {
        AxiosAuth.currentUserAuth(currentUser)
            .post(ConfigApi.API_PROFILE_UPDATE.replace(':type', typeData), {
                name,
                contact,
            })
            .then(({ data }) => {
                setMessage({ text: `${data.message}`, timeout: 10000 });
                setShow(false);
            });
    };

    return (
        <Modal
            size="md"
            show={show}
            onHide={() => {
                setShow(false);
            }}
            centered
            aria-labelledby="example-modal-sizes-title-lg"
            contentClassName="custom-profile-change-content btn-close-custom"
        >
            <Modal.Header closeButton>
                <Modal.Title>Update Profile</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group
                        className="custom-login-input"
                        style={{ display: typeData === 'name' ? 'block' : 'none' }}
                    >
                        <Form.Label className="custom-label">Name</Form.Label>
                        <Form.Control
                            className="custom-input"
                            type="text"
                            placeholder="Name"
                            name="name"
                            value={name}
                            onChange={handleValue}
                        />
                    </Form.Group>

                    <Form.Group
                        className="custom-login-input"
                        style={{ display: typeData === 'contact' ? 'block' : 'none' }}
                    >
                        <Form.Label className="custom-label">Contact</Form.Label>
                        <Form.Control
                            className="custom-input"
                            type="text"
                            placeholder="Contact"
                            name="contact"
                            onChange={handleValue}
                            value={contact}
                        />
                    </Form.Group>

                    <Button
                        variant="primary"
                        type="button"
                        onClick={handleProfileChange}
                        className="custom-login-btn"
                        name="birthDate"
                    >
                        Change
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default UserUpdateModals;
