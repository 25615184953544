import React from 'react';
import { Outlet } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthProvider';
import Footer from '../../system/components/Footer';
// import BrowserInfo from '../../../utils/BrowserInfo';
import HeaderSection from '../../system/components/HeaderSection';

function UserAuthHandlerWide() {
    const { currentUser, isSignIn } = useAuth();
    // const navigate = useNavigate();
    // const url = '/cart/';
    // const browserInfo = BrowserInfo();
    // useEffect(() => {
    //     if (!isSignIn) {
    //         openLoginModal(() => {
    //             navigate(url);
    //         }, null);
    //     }
    //     console.log('ok');
    // }, [isSignIn, navigate, openLoginModal]);

    if (currentUser === null) {
        // return <div>Please Login</div>;
        return <div>Loading User Information ...</div>;
    }

    if (!isSignIn) {
        return (
            <>
                <HeaderSection whiteMenu />;
            </>
        );
    }

    return (
        <>
            <HeaderSection whiteMenu />

            <Outlet />

            <Footer display="block" />
        </>
    );
}

export default UserAuthHandlerWide;
