import React, { useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import ConfigApi from '../../../../../configs/ConfigApi';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import AxiosAuth from '../../../../utils/AxiosAuth';

const countryCode = process.env.REACT_APP_COUNTRY_CODE;

function ForgetPassFormBody({ returnToLogin }) {
    const refPhone = useRef();
    const refOtp = useRef();
    const refPassword1 = useRef();
    const refPassword2 = useRef();
    const { setMessage } = useTemplate();

    const [responseData, setResponseData] = useState({
        path: 'verify-contact', // verify-contact, verify-otp, set-password
        countryCode,
        phone: '',
        otp: '',
        password1: '',
        password2: '',
        key: '',
    });

    const handleSubmitClick = (ev) => {
        ev.preventDefault();

        const phone = refPhone.current.value;
        const otp = refOtp.current.value;
        const password1 = refPassword1.current.value;
        const password2 = refPassword2.current.value;

        AxiosAuth.post(`${ConfigApi.API_RESET_PASS}${responseData.path}/`, {
            countryCode,
            phone,
            otp,
            password1,
            password2,
            key: responseData.key,
        }).then(({ data }) => {
            setMessage(data.message);

            if (data.error === 0) {
                // --Checking Local User
                setResponseData((cuState) => ({ ...cuState, ...data }));

                if (data.focus) {
                    [data.focus].current.focus();
                }
            }

            if (data.success) {
                returnToLogin();
            }
        });
    };

    useEffect(() => {
        refPhone.current.value = responseData.phone;
        refOtp.current.value = responseData.otp;
        refPassword1.current.value = responseData.password1;
        refPassword2.current.value = responseData.password2;
    }, [responseData.otp, responseData.password1, responseData.password2, responseData.phone]);

    return (
        <Form onSubmit={handleSubmitClick}>
            <Form.Group className="custom-login-input">
                <Form.Label className="custom-label">Phone</Form.Label>
                <Form.Control
                    ref={refPhone}
                    className="custom-input"
                    type="phone"
                    placeholder="880"
                    readOnly={responseData.path !== 'verify-contact'}
                />
            </Form.Group>

            <div
                className={
                    responseData.path === 'verify-otp' || responseData.path === 'set-password'
                        ? 'd-block'
                        : 'd-none'
                }
            >
                <Form.Group className="custom-login-input">
                    <Form.Label className="custom-label">OTP</Form.Label>
                    <Form.Control
                        ref={refOtp}
                        className="custom-input"
                        type="text"
                        placeholder="OTP"
                        readOnly={responseData.path !== 'verify-otp'}
                    />
                </Form.Group>
            </div>

            <div className={responseData.path === 'set-password' ? 'd-block' : 'd-none'}>
                <div className="row">
                    <div className="col-6">
                        <Form.Group className="custom-login-input">
                            <Form.Label className="custom-label">Password</Form.Label>
                            <Form.Control
                                ref={refPassword1}
                                className="custom-input"
                                type="password"
                                placeholder="Password"
                                readOnly={responseData.path !== 'set-password'}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-6">
                        <Form.Group className="custom-login-input">
                            <Form.Label className="custom-label">Re-Password</Form.Label>
                            <Form.Control
                                ref={refPassword2}
                                className="custom-input"
                                type="password"
                                placeholder="Password"
                                readOnly={responseData.path !== 'set-password'}
                            />
                        </Form.Group>
                    </div>
                </div>
            </div>

            <Button variant="primary" type="submit" className="custom-login-btn">
                Change Password
            </Button>
        </Form>
    );
}

export default ForgetPassFormBody;
