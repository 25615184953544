import { faPhone, faPhoneFlip, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import ConfigApi from '../../../../configs/ConfigApi';
import { useAuth } from '../../../contexts/AuthProvider';
import { useTemplate } from '../../../contexts/TemplateProvider';
import AxiosAuth from '../../../utils/AxiosAuth';

const countryCode = process.env.REACT_APP_COUNTRY_CODE;

function UserPhoneChnageModal({ show, setShow }) {
    const { currentUser } = useAuth();
    const refPhone = useRef();
    const refOtp = useRef();
    const [newPhone, setNewPhone] = useState();
    const [userProvider, setUserProvider] = useState();
    const { setMessage } = useTemplate();
    const [selectedPhone, setSelectedPhone] = useState(currentUser.phoneNumber);
    const [showPhoneInput, setShowPhoneInput] = useState(false);
    const [showOtpInput, setShowOtpInput] = useState(false);
    const [uKey, setUKey] = useState(false);

    const handleSendPhoneForOtp = () => {
        AxiosAuth.currentUserAuth(currentUser)
            .post(ConfigApi.API_USER_ADD_PHONE_OTP, {
                adPhone: refPhone.current.value,
                countryCode,
            })
            .then(({ data }) => {
                setMessage({ text: `${data.message}`, timeout: 10000 });
                if (data.error === 0) {
                    setNewPhone(refPhone.current.value);
                    setShowOtpInput(true);
                    setShowPhoneInput(false);
                    setUKey(data.key);
                }
            });
    };
    const handleVerifyOtp = () => {
        AxiosAuth.currentUserAuth(currentUser)
            .post(ConfigApi.API_USER_VERIFY_PHONE_OTP, {
                uKey,
                otp: refOtp.current.value,
                phone: newPhone,
                countryCode,
            })
            .then(({ data }) => {
                setMessage({ text: `${data.message}`, timeout: 10000 });
                if (data.error === 0) {
                    setShowOtpInput(false);
                    setShowPhoneInput(false);
                    setUserProvider(data.provider);
                }
            });
    };

    const handleChnageDefaultPhone = (ev) => {
        AxiosAuth.currentUserAuth(currentUser)
            .post(ConfigApi.API_USER_DEFAULT_PHONE_CHANGE, {
                phone: ev,
                countryCode,
            })
            .then(({ data }) => {
                if (data.error === 0) {
                    setSelectedPhone(ev);
                }
                setMessage({ text: `${data.message}`, timeout: 10000 });
            });
    };
    const handleRemovePhone = (ev) => {
        AxiosAuth.currentUserAuth(currentUser)
            .post(ConfigApi.API_USER_DEFAULT_PHONE_REMOVE, {
                phone: ev,
                countryCode,
            })
            .then(({ data }) => {
                setMessage({ text: `${data.message}`, timeout: 10000 });
                if (data.error === 0) {
                    setUserProvider(data.provider);
                }
            });
    };

    useEffect(() => {
        AxiosAuth.currentUserAuth(currentUser)
            .get(ConfigApi.API_PROVIDER_PHONE_DATA)
            .then(({ data }) => {
                setUserProvider(data);
            });
    }, [currentUser]);

    return (
        <Modal
            size="md"
            show={show}
            onHide={() => {
                setShow(false);
            }}
            centered
            aria-labelledby="example-modal-sizes-title-lg"
            contentClassName="custom-profile-change-content btn-close-custom"
        >
            <Modal.Header closeButton>
                <Modal.Title>Manage Phone</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <table style={{ width: '100%', marginBottom: 10 }}>
                    <thead>
                        <tr>
                            <td colSpan={2}>Phone</td>
                            <td>Default</td>
                            <td>Remove</td>
                        </tr>
                    </thead>
                    <tbody>
                        {userProvider?.map((pro) => (
                            <tr style={{ height: 40 }} key={pro.phone_number}>
                                <td>
                                    {selectedPhone === pro.phone_number ? (
                                        <FontAwesomeIcon
                                            icon={faPhone}
                                            style={{ marginRight: 8 }}
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            icon={faPhoneFlip}
                                            style={{ marginRight: 8 }}
                                        />
                                    )}
                                </td>
                                <td>{pro.phone_number}</td>
                                <td style={{ textAlign: 'center' }}>
                                    <Form.Check
                                        checked={selectedPhone === pro.phone_number}
                                        onChange={() => {
                                            handleChnageDefaultPhone(pro.phone_number);
                                        }}
                                        name="radio-button-demo"
                                        type="radio"
                                        className="user-address-radio-check"
                                    />
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                    {selectedPhone !== pro.phone_number ? (
                                        <FontAwesomeIcon
                                            icon={faXmark}
                                            onClick={() => {
                                                handleRemovePhone(pro.phone_number);
                                            }}
                                            style={{ color: 'red' }}
                                        />
                                    ) : (
                                        ''
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <Form>
                    <Form.Group className="custom-login-input">
                        <Form.Label
                            className="custom-label"
                            onClick={() => setShowPhoneInput(true)}
                        >
                            Add Phone
                        </Form.Label>
                        {showPhoneInput && !showOtpInput ? (
                            <>
                                <Form.Control
                                    className="custom-input mb-4"
                                    type="text"
                                    placeholder="0123456789"
                                    name="add_email"
                                    ref={refPhone}
                                />
                                <Button
                                    variant="primary"
                                    type="button"
                                    onClick={handleSendPhoneForOtp}
                                    className="custom-login-btn"
                                    name="birthDate"
                                >
                                    Save Phone
                                </Button>
                            </>
                        ) : (
                            ''
                        )}
                        {showOtpInput && !showPhoneInput ? (
                            <>
                                <Form.Control
                                    className="custom-input-otp text-center"
                                    type="email"
                                    placeholder="OTP"
                                    ref={refOtp}
                                />
                                <Button
                                    variant="primary"
                                    type="button"
                                    onClick={handleVerifyOtp}
                                    className="custom-login-btn"
                                    name="birthDate"
                                >
                                    Verify OTP
                                </Button>
                            </>
                        ) : (
                            ''
                        )}
                    </Form.Group>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default UserPhoneChnageModal;
