import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef } from 'react';
import { Button } from 'react-bootstrap';
import { useAuth } from '../contexts/AuthProvider';
import { useCart } from '../contexts/CartProvider';
import { useTemplate } from '../contexts/TemplateProvider';

function BuyNowButton({
    variant,
    myClassName,
    icon,
    title,
    size,
    productId,
    selectedVariation,
    isDisable = false,
    handleModalClose,
    newQuantity,
}) {
    const { openLoginModal, currentUser } = useAuth();
    const { addToCart } = useCart();
    const { setMessage } = useTemplate();
    const refButton = useRef();

    const handelBuyNow = () => {
        if (!currentUser || currentUser.userFound === false) {
            openLoginModal(() => {
                refButton.current.click();
            });
        } else if (!productId) {
            setMessage('Invalid Product ID');
        } else if (newQuantity <= 0) {
            setMessage('Invalid Quantity');
        } else {
            addToCart(productId, selectedVariation, newQuantity, true);
            setMessage({ text: 'Product added to cart', timeout: 8000 });

            if (typeof handleModalClose === 'function') {
                handleModalClose();
            }
        }
    };

    return (
        <Button
            ref={refButton}
            variant={variant || 'outline-info'}
            className={[myClassName || 'ThemeBtn'].join(' ')}
            size={size || 'sm'}
            onClick={handelBuyNow}
            disabled={isDisable}
        >
            {icon && <FontAwesomeIcon icon={icon} />}
            {icon ? ' ' : ''} {title || 'Button'}
        </Button>
    );
}

export default BuyNowButton;
