import React from 'react';
import IconTik from '../../../../assets/images/IconTick.svg';
import ReactGA4 from '../../../utils/ReactGA4';

function UserPointsPage() {
    return (
        <div className="col-12 col-lg-9">
            <ReactGA4 />
            <div className="user-profile" style={{ paddingTop: 0 }}>
                <div className="user-voucher-section">
                    <div className="row gx-3 gy-3">
                        <div className="col-12">
                            <div className="user-points-title">
                                <div className="user-points-title-info">
                                    <div className="user-points-title-total">
                                        <h5>You Have</h5>
                                        <h1>
                                            875 <b>Points</b>
                                        </h1>
                                    </div>
                                    <div className="user-points-title-daily">
                                        <button type="button">
                                            <img src={IconTik} alt="Tik" />
                                            Daily Check
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 col-xl-6 col-md-6">
                            <div className="user-voucher-list">
                                <div className="user-voucher-list-amount">
                                    <h4>৳50</h4>
                                </div>
                                <div className="user-voucher-list-divider">
                                    <div className="top-half-circle" />
                                    <div className="user-voucher-list-divider-line">
                                        <div className="line" />
                                    </div>
                                    <div className="bottom-half-circle" />
                                </div>
                                <div className="user-voucher-list-detail">
                                    <div>
                                        <h5>500 Points to redeem</h5>
                                        <h6>For a purchase over ৳500</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 col-xl-6 col-md-6">
                            <div className="user-voucher-list">
                                <div className="user-voucher-list-amount">
                                    <h4>৳100</h4>
                                </div>
                                <div className="user-voucher-list-divider">
                                    <div className="top-half-circle" />
                                    <div className="user-voucher-list-divider-line">
                                        <div className="line" />
                                    </div>
                                    <div className="bottom-half-circle" />
                                </div>
                                <div className="user-voucher-list-detail">
                                    <div>
                                        <h5>1000 Points to redeem</h5>
                                        <h6>For a purchase over ৳1500</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserPointsPage;
