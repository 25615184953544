/* eslint-disable no-unused-vars */
import { faList, faTh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Badge, Button, Container, Form, ListGroup, Tooltip } from 'react-bootstrap';
import LazyLoad from 'react-lazyload';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import IconMinus from '../../../../assets/images/IconMinus.svg';
import AppInitDispatch from '../../../dispatches/AppInitDispatch';
import FindInArray from '../../../utils/FindInArray';
import InitInfo from '../../../utils/InitInfo';
import ReactGA4 from '../../../utils/ReactGA4';
import ProductPageDataMulti from '../../products/components/ProductItem';
import ProductPageSingle from '../../products/components/ProductPageSingle';
import Footer from '../components/Footer';
import HeaderSection from '../components/HeaderSection';

function LabelOneItem({ data, keyName, handleToggle }) {
    const labelId = `checkbox-list-label-${keyName}`;

    const titleWithBadge = (
        <Badge
            edge="start"
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            color="primary"
        >
            <span>{`${data[keyName]?.title}`}</span>
        </Badge>
    );

    return (
        <>
            <div>{titleWithBadge}</div>
            <ListGroup role={undefined} dense button onClick={handleToggle(keyName)}>
                <ListGroup.Item
                    id={labelId}
                    primary={titleWithBadge}
                    primaryTypographyProps={{ style: { fontWeight: 'bold', fontSize: '120%' } }}
                />
                <Badge
                    edge="end"
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    color="secondary"
                />
            </ListGroup>
        </>
    );
}
function LabelTwoList({ classes, variants, keyName, handleToggleSub, data }) {
    const labelId = `checkbox-list-label-${keyName}`;

    if (keyName === 'color') {
        return (
            <div className="colors">
                {data?.map((item) => (
                    <ColorsItem
                        labelId={labelId}
                        handleToggleSub={handleToggleSub}
                        item={item}
                        key={item}
                        variants={variants}
                        keyName={keyName}
                    />
                ))}
            </div>
        );
    }

    if (keyName === 'ageRange') {
        return (
            <div className="colors">
                {data?.map((item) => (
                    <AgeItem
                        labelId={labelId}
                        handleToggleSub={handleToggleSub}
                        item={item}
                        key={item}
                        variants={variants}
                        keyName={keyName}
                    />
                ))}
            </div>
        );
    }

    return (
        <ListGroup component="div" disablePadding dense>
            {data?.map((item) => (
                <LabelTwoItem
                    classes={classes}
                    labelId={labelId}
                    handleToggleSub={handleToggleSub}
                    item={item}
                    key={item}
                    variants={variants}
                    keyName={keyName}
                />
            ))}
        </ListGroup>
    );
}
function LabelTwoItem({ variants, keyName, handleToggleSub, item }) {
    const checked = variants[keyName] ? variants[keyName]?.indexOf(item) !== -1 : false;

    return (
        <Tooltip title={item}>
            <div className={['size-item', checked ? 'checked' : ''].join(' ')}>
                <button type="button" onClick={handleToggleSub(keyName, item)}>
                    {item}
                </button>
            </div>
        </Tooltip>
    );
}
function AgeItem({ variants, keyName, handleToggleSub, item }) {
    const checked = variants[keyName] ? variants[keyName]?.indexOf(item) !== -1 : false;

    return (
        <Tooltip title={item}>
            <div className={['age-item', checked ? 'checked' : ''].join(' ')}>
                <button type="button" onClick={handleToggleSub(keyName, item)}>
                    {item}
                </button>
            </div>
        </Tooltip>
    );
}
function ColorsItem({ variants, keyName, handleToggleSub, item }) {
    const checked = variants[keyName] ? variants[keyName]?.indexOf(item) !== -1 : false;

    return (
        <Tooltip title={item}>
            <div className={['color-item', checked ? 'checked' : ''].join(' ')}>
                <button
                    type="button"
                    style={{ backgroundColor: item }}
                    onClick={handleToggleSub(keyName, item)}
                />
            </div>
        </Tooltip>
    );
}
function ProductVarient({ data, handleToggle, handleToggleSub, variants }) {
    return (
        <ListGroup>
            {Object.keys(data)?.map((keyName) => (
                <div key={keyName}>
                    <LabelOneItem
                        data={data}
                        keyName={keyName}
                        handleToggle={handleToggle}
                        variants={variants}
                    />
                    {data[keyName] && variants && (
                        <LabelTwoList
                            keyName={keyName}
                            open={!!variants[keyName]}
                            data={data[keyName].values}
                            handleToggleSub={handleToggleSub}
                            variants={variants}
                        />
                    )}
                </div>
            ))}
        </ListGroup>
    );
}
function CategoryProducts({ itemData, myClass }) {
    return (
        <div
            className={[
                'col mb-4 col-m-product',
                myClass === 'product-section-colI1'
                    ? 'product-section-colI1'
                    : 'product-section-colI3',
            ].join(' ')}
        >
            {myClass === 'product-section-colI1' ? (
                <LazyLoad throttle={200} height={300}>
                    <ProductPageSingle itemData={itemData} />
                </LazyLoad>
            ) : (
                <LazyLoad throttle={200} height={300}>
                    <ProductPageDataMulti itemData={itemData} />
                </LazyLoad>
            )}
        </div>
    );
}
function CategoryPageFilter({ myClass, handleColumOne, handleColumThree }) {
    return (
        <div className="col-12 col-m-product">
            <div className="page-filter-view">
                <div className="pageFilter">
                    <div className="page-filter-option">
                        <div className=" btn-group">
                            <Button
                                size="sm"
                                active={myClass === 'product-section-colI1'}
                                variant="outline-danger"
                                onClick={handleColumOne}
                            >
                                <FontAwesomeIcon icon={faList} />
                            </Button>
                            <Button
                                size="sm "
                                active={myClass === 'product-section-colI3'}
                                variant="outline-danger"
                                onClick={handleColumThree}
                            >
                                <FontAwesomeIcon icon={faTh} />
                            </Button>
                        </div>
                        <div className="btn-group" style={{ float: 'right' }}>
                            <Button size="sm " variant="outline-danger">
                                Best Sellers
                            </Button>
                            <Button size="sm " variant="outline-danger">
                                Newest
                            </Button>
                            <Button size="sm " variant="outline-danger">
                                Top Rated
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
function SearchPage() {
    const { shopData, products } = InitInfo();
    const [searchParams, setSearchParams] = useSearchParams();
    const searchItem = searchParams.get('k');

    const dispatch = useDispatch();
    const productData = products;
    const productVariantList = shopData.productVariantList ? shopData.productVariantList : [];
    const history = useNavigate();
    const [categoryId, setCategoryId] = useState('');
    const [myClass, setMyClass] = useState('product-section-colI3');
    const [variants, setVariants] = useState([]);

    const handleToggle = (value) => () => {
        const newVariants = { ...variants };

        if (!variants[value]) {
            newVariants[value] = [];
        } else {
            delete newVariants[value];
        }

        setVariants(newVariants);
    };

    const handleToggleSub = (key, value) => () => {
        const currentIndex = variants[key]?.indexOf(value);
        const newVariants = { ...variants };

        if (currentIndex === -1) {
            newVariants[key].push(value);
        } else {
            newVariants[key]?.splice(currentIndex, 1);
        }

        setVariants(newVariants);
    };

    const handleColumThree = () => {
        setMyClass('product-section-colI3');
    };
    const handleColumOne = () => {
        setMyClass('product-section-colI1');
    };

    useEffect(() => {
        // effect

        AppInitDispatch(dispatch)
            .then(() => {
                setVariants({ size: Array(0), color: Array(0), ageRange: Array(0) });
            })
            .catch((err) => {
                console.log(err);
            });
    }, [dispatch, history]);

    const FindArr = new FindInArray();
    FindArr.init(productData, 'id');

    return (
        <>
            <ReactGA4 />
            <HeaderSection whiteMenu />
            <section className="PageSection">
                <Container>
                    <div className="page-banner" />

                    <div className="row">
                        <div className="d-none d-lg-block d-xl-block col-lg-3 col-lg-3">
                            <div className="category-product-sidebar">
                                <div className="category-product-sidebar-title">Filter By</div>
                                <div className="category-product-sidebar-cat">
                                    <div className="category-product-sidebar-cat-hrader">
                                        <p>Categories</p>
                                        <button type="button">
                                            <img src={IconMinus} alt="" />
                                        </button>
                                    </div>
                                    <div className="category-product-sidebar-cat-detail">
                                        <Form.Check
                                            inline
                                            label="Man"
                                            name="group1"
                                            type="checkbox"
                                            className="custom-checkbox"
                                        />
                                        <Form.Check
                                            inline
                                            label="Woman"
                                            name="group1"
                                            type="checkbox"
                                            className="custom-checkbox"
                                        />
                                        <Form.Check
                                            inline
                                            label="Kids"
                                            name="group1"
                                            type="checkbox"
                                            className="custom-checkbox"
                                        />
                                    </div>
                                </div>
                                <div className="category-product-sidebar-cat">
                                    <div className="category-product-sidebar-cat-hrader">
                                        <p>Color</p>
                                        <button type="button">
                                            <img src={IconMinus} alt="" />
                                        </button>
                                    </div>
                                    <div className="category-product-sidebar-cat-detail">
                                        <Form.Check aria-label="radio 1" className="custom-radio">
                                            <Form.Check.Input
                                                type="radio"
                                                style={{ backgroundColor: 'red' }}
                                            />
                                            <Form.Check.Label>Red</Form.Check.Label>
                                        </Form.Check>
                                        <Form.Check aria-label="radio 1" className="custom-radio">
                                            <Form.Check.Input
                                                type="radio"
                                                style={{ backgroundColor: 'blue' }}
                                            />
                                            <Form.Check.Label>Blue</Form.Check.Label>
                                        </Form.Check>
                                        <Form.Check aria-label="radio 1" className="custom-radio">
                                            <Form.Check.Input
                                                type="radio"
                                                style={{ backgroundColor: 'green' }}
                                            />
                                            <Form.Check.Label>Green</Form.Check.Label>
                                        </Form.Check>
                                        <Form.Check aria-label="radio 1" className="custom-radio">
                                            <Form.Check.Input
                                                type="radio"
                                                style={{ backgroundColor: 'yellow' }}
                                            />
                                            <Form.Check.Label>Yellow</Form.Check.Label>
                                        </Form.Check>
                                        <Form.Check aria-label="radio 1" className="custom-radio">
                                            <Form.Check.Input
                                                type="radio"
                                                style={{ backgroundColor: 'purple' }}
                                            />
                                            <Form.Check.Label>Purple</Form.Check.Label>
                                        </Form.Check>
                                        <Form.Check aria-label="radio 1" className="custom-radio">
                                            <Form.Check.Input
                                                type="radio"
                                                style={{ backgroundColor: 'olive' }}
                                            />
                                            <Form.Check.Label>Olive</Form.Check.Label>
                                        </Form.Check>
                                        <Form.Check aria-label="radio 1" className="custom-radio">
                                            <Form.Check.Input
                                                type="radio"
                                                style={{ backgroundColor: 'skyblue' }}
                                            />
                                            <Form.Check.Label>Skyblue</Form.Check.Label>
                                        </Form.Check>
                                        <Form.Check aria-label="radio 1" className="custom-radio">
                                            <Form.Check.Input
                                                type="radio"
                                                style={{ backgroundColor: 'navy' }}
                                            />
                                            <Form.Check.Label>Navy</Form.Check.Label>
                                        </Form.Check>
                                    </div>
                                </div>
                                <div className="category-product-sidebar-cat">
                                    <div className="category-product-sidebar-cat-hrader">
                                        <p>Size</p>
                                        <button type="button">
                                            <img src={IconMinus} alt="" />
                                        </button>
                                    </div>
                                    <div className="category-product-sidebar-cat-detail">
                                        <Form.Check
                                            inline
                                            label="S"
                                            name="group1"
                                            type="checkbox"
                                            className="custom-checkbox"
                                        />
                                        <Form.Check
                                            inline
                                            label="M"
                                            name="group1"
                                            type="checkbox"
                                            className="custom-checkbox"
                                        />
                                        <Form.Check
                                            inline
                                            label="L"
                                            name="group1"
                                            type="checkbox"
                                            className="custom-checkbox"
                                        />
                                        <Form.Check
                                            inline
                                            label="XL"
                                            name="group1"
                                            type="checkbox"
                                            className="custom-checkbox"
                                        />
                                        <Form.Check
                                            inline
                                            label="XxL"
                                            name="group1"
                                            type="checkbox"
                                            className="custom-checkbox"
                                        />
                                    </div>
                                </div>
                                <div className="category-product-sidebar-cat">
                                    <div className="category-product-sidebar-cat-hrader">
                                        <p>Age</p>
                                        <button type="button">
                                            <img src={IconMinus} alt="" />
                                        </button>
                                    </div>
                                    <div className="category-product-sidebar-cat-detail">
                                        <Form.Check
                                            inline
                                            label="0-3 Month"
                                            name="group1"
                                            type="checkbox"
                                            className="custom-checkbox"
                                        />
                                        <Form.Check
                                            inline
                                            label="3-6 Month"
                                            name="group1"
                                            type="checkbox"
                                            className="custom-checkbox"
                                        />
                                        <Form.Check
                                            inline
                                            label="6-12 Month"
                                            name="group1"
                                            type="checkbox"
                                            className="custom-checkbox"
                                        />
                                        <Form.Check
                                            inline
                                            label="1 Year"
                                            name="group1"
                                            type="checkbox"
                                            className="custom-checkbox"
                                        />
                                        <Form.Check
                                            inline
                                            label="2 Year"
                                            name="group1"
                                            type="checkbox"
                                            className="custom-checkbox"
                                        />
                                        <Form.Check
                                            inline
                                            label="3-4 Year"
                                            name="group1"
                                            type="checkbox"
                                            className="custom-checkbox"
                                        />
                                        <Form.Check
                                            inline
                                            label="5-6 Year"
                                            name="group1"
                                            type="checkbox"
                                            className="custom-checkbox"
                                        />
                                    </div>
                                </div>
                                {/* <ProductVarient
                                    data={productVariantList}
                                    handleToggle={handleToggle}
                                    handleToggleSub={handleToggleSub}
                                    variants={variants}
                                /> */}
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-9 col-lg-9">
                            <div className="row row-m-product">
                                <CategoryPageFilter
                                    myClass={myClass}
                                    handleColumOne={handleColumOne}
                                    handleColumThree={handleColumThree}
                                />

                                {productData &&
                                    productData

                                        .filter((i) =>
                                            i.title.toLowerCase().includes(searchItem.toLowerCase())
                                        )
                                        .map((item) => {
                                            if (item) {
                                                return (
                                                    <CategoryProducts
                                                        itemData={item}
                                                        myClass={myClass}
                                                        key={item.id}
                                                    />
                                                );
                                            }
                                            return <></>;
                                        })}
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
            <Footer />
        </>
    );
}

export default SearchPage;
