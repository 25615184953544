/* eslint-disable prettier/prettier */

import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

function SupportCompleteModal(props) {
    const { modalShow, completeTicket, setCompleteModalShow, isSolved, comment, handleReviewValueChange } = props;
    return (
        <>
            <Modal
                size="sm"
                show={modalShow}
                onHide={() => setCompleteModalShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Review Ticket</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="row">
                        <div className="col-12 col-lg-12">
                            <Form.Group >
                                <Form.Label>Problem Solved ??</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="isSolved"
                                    value={isSolved}
                                    onChange={handleReviewValueChange}
                                >
                                    <option value="1" key={1}>
                                        Yes
                                    </option>
                                    <option value="0" key={2}>
                                        No
                                    </option>

                                </Form.Control>
                            </Form.Group>
                            <Form.Group >
                                <Form.Label>Comment For Service</Form.Label>
                                <input className="form-control" name="comment" value={comment} onChange={handleReviewValueChange} type="text" placeholder="Enter Comment" />
                            </Form.Group>
                            <Button style={{ float: 'right' }} variant="primary" onClick={completeTicket}>
                                Save
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default SupportCompleteModal;


