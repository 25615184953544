const libraryExecuted = {};

function LoadScript(libraryName, url, id) {
    return new Promise((resolve, reject) => {
        if (!libraryExecuted[libraryName]) {
            const script = document.createElement('script');
            script.onload = () => {
                libraryExecuted[libraryName] = true;
                resolve(id);
            };
            script.src = url;
            script.id = id;
            script.onerror = () => {
                reject(Error('Failed to load Library'));
            };
            document.head.appendChild(script);
        } else {
            resolve(id);
        }
    });
}

export default LoadScript;
