import React from 'react';
import Modal from 'react-bootstrap/Modal';
import PlaceholderImg from '../../../../assets/images/placeholder-image.png';
import ProductOverviewInfo from './productdetails/ProductOverviewInfo';

function ProductVariantModal({ productData, modalShow, handleModalClose }) {
    return (
        <Modal
            size="lg"
            show={modalShow}
            onHide={handleModalClose}
            backdrop="static"
            keyboard={false}
            contentClassName="product-view-modal btn-close-custom"
            centered
        >
            <Modal.Header closeButton className="product-view-modal-header" />
            <Modal.Body style={{ marginTop: '-25px' }}>
                <div className="row">
                    <div className="col-12 col-lg-5">
                        {productData?.default_image ? (
                            <img
                                src={`https://www.optimizee.xyz/images/${btoa(
                                    productData.default_image
                                )}.jpg`}
                                alt="Images"
                                className="product-view-modal-img"
                            />
                        ) : (
                            <img
                                src={PlaceholderImg}
                                alt="Images"
                                className="product-view-modal-img"
                            />
                        )}
                    </div>
                    <div className="col-12 col-lg-7">
                        <ProductOverviewInfo
                            data={productData}
                            productCategoryList={[]}
                            shopId=""
                            isCatShow={false}
                            handleModalClose={handleModalClose}
                        />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ProductVariantModal;
