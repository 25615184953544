/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef } from 'react';
import { Button, Form } from 'react-bootstrap';
import ConfigApi from '../../../../../configs/ConfigApi';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import AxiosAuth from '../../../../utils/AxiosAuth';
import ValidatePhonePattern from '../../../../utils/ValidatePhonePattern';

const countryCode = process.env.REACT_APP_COUNTRY_CODE;

function RegistrationFormBody({
    // signUp,
    setOptConfig,
    signInWithGoogle,
    signInWithFacebook,
    signInWithTwitter,
    openLoginModal,
}) {
    const refName = useRef();
    const refPhone = useRef();
    const refPassword1 = useRef();
    const refPassword2 = useRef();
    const refAcceptTc = useRef();
    const { setMessage } = useTemplate();

    const handleSignUpClick = (ev) => {
        ev.preventDefault();

        const name = refName.current.value;
        const phone = refPhone.current.value;
        const password1 = refPassword1.current.value;
        const password2 = refPassword2.current.value;
        const acceptTc = refAcceptTc.current.checked;

        setMessage('Loading...');

        if (!name) {
            refName.current.focus();
            setMessage('Enter valid name');
        } else if (!phone) {
            refPhone.current.focus();
            setMessage('Enter valid phone number');
        } else if (!ValidatePhonePattern(countryCode, phone)) {
            refPhone.current.focus();
            setMessage('Enter valid phone number');
        } else if (!password1) {
            refPassword1.current.focus();
            setMessage('Enter password');
        } else if (password1.length < 6) {
            refPassword1.current.focus();
            setMessage('Password must be Six character length');
        } else if (password1 !== password2) {
            refPassword2.current.focus();
            setMessage('Passwords are not same');
        } else if (!acceptTc) {
            setMessage('Must accept the trams and condition');
        } else {
            AxiosAuth.post(`${ConfigApi.API_REGISTRATION}phone/data/`, {
                name,
                countryCode,
                phone,
                password: password1,
            }).then(({ data }) => {
                setMessage(data.message);

                if (data.error === 0) {
                    // --Checking Local User

                    setOptConfig({
                        formData: {
                            name,
                            countryCode,
                            phone: data.phone,
                            password: password1,
                        },
                        sendOtpTo: phone,
                        sendingStatus: 'count-down', // send, count-down, resend
                        sendingDelay: 60,
                        key: data.key,
                    });
                }
            });
        }
    };

    // useEffect(() => {
    //     refPhone.current.value = countryCode;
    // });

    return (
        <>
            <Form onSubmit={handleSignUpClick}>
                <Form.Group className="custom-login-input">
                    <Form.Label className="custom-label">Name</Form.Label>
                    <Form.Control
                        ref={refName}
                        className="custom-input"
                        type="name"
                        placeholder="Enter Name"
                    />
                </Form.Group>

                <Form.Group className="custom-login-input">
                    <Form.Label className="custom-label">Phone</Form.Label>
                    <Form.Control
                        ref={refPhone}
                        className="custom-input"
                        type="phone"
                        placeholder="880"
                    />
                </Form.Group>

                <div className="row">
                    <div className="col-6">
                        <Form.Group className="custom-login-input">
                            <Form.Label className="custom-label">Password</Form.Label>
                            <Form.Control
                                ref={refPassword1}
                                className="custom-input"
                                type="password"
                                placeholder="Password"
                            />
                        </Form.Group>
                    </div>
                    <div className="col-6">
                        <Form.Group className="custom-login-input">
                            <Form.Label className="custom-label">Re-Password</Form.Label>
                            <Form.Control
                                ref={refPassword2}
                                className="custom-input"
                                type="password"
                                placeholder="Password"
                            />
                        </Form.Group>
                    </div>
                </div>

                <Form.Group className="custom-login-other">
                    <Form.Check
                        inline
                        label={
                            <>
                                Accept{' '}
                                <a href="/terms-condition/" target="_blank">
                                    terms & Condition
                                </a>
                            </>
                        }
                        ref={refAcceptTc}
                        className="custom-login-other-label"
                        name="group1"
                        type="checkbox"
                    />
                    {/* <Link to="/forgot">Forgot Password ?</Link> */}
                </Form.Group>

                <Button variant="primary" type="submit" className="custom-login-btn">
                    Create account
                </Button>
            </Form>

            <Form.Group className="custom-login-other" style={{ textAlign: 'center' }}>
                <p>or</p>
            </Form.Group>

            <Form.Group className="custom-login-social">
                <Button
                    type="button"
                    onClick={() => {
                        signInWithGoogle();
                    }}
                    className="custom-login-btn-google"
                >
                    Google
                </Button>
                <Button
                    type="button"
                    className="custom-login-btn-facebook"
                    onClick={signInWithFacebook}
                >
                    Facebook
                </Button>
                <Button
                    type="button"
                    className="custom-login-btn-twitter"
                    onClick={signInWithTwitter}
                >
                    Twitter
                </Button>
            </Form.Group>

            <Form.Group className="custom-login-footer">
                <p>
                    Don&apos;t have an account?{' '}
                    <span
                        onClick={() => {
                            openLoginModal();
                        }}
                    >
                        Back to Login
                    </span>
                </p>
            </Form.Group>
        </>
    );
}

export default RegistrationFormBody;
