import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PaymentComplete from '../../../../assets/images/payment-compete.svg';

function OrderCompletedSection({ invoiceData }) {
    return (
        <section className="cart-section">
            <Container>
                <div className="order-completed-section">
                    <img src={PaymentComplete} alt="" />
                </div>
                <div className="order-completed-section-text">Order Completed</div>
                <div className="order-completed-section-btn">
                    <button type="button">
                        <Link to={`/user/order-details/${invoiceData?.invoiceInfo?.id}/`}>
                            View Order
                        </Link>
                    </button>
                    <button type="button">
                        <Link to="/">Continue Shopping</Link>
                    </button>
                </div>
            </Container>
        </section>
    );
}

export default OrderCompletedSection;
