import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CartPage from './app/cart/pages/CartPage';
import CheckoutPage from './app/cart/pages/CheckoutPage';
import InvoicePage from './app/cart/pages/InvoicePage';
import OrderSuccessPage from './app/cart/pages/OrderSuccessPage';
import PaymentPage from './app/cart/pages/PaymentPage';
import CategoryPage from './app/products/pages/CategoryPage';
import CategoryPageAll from './app/products/pages/CategoryPageAll';
import ProductDetailsPage from './app/products/pages/ProductDetailsPage';
import AboutUsPage from './app/system/pages/AboutUsPage';
import IndexPage from './app/system/pages/IndexPage';
import SearchPage from './app/system/pages/SearchPage';
import TermsPage from './app/system/pages/TermsPage';
import UserAuthHandler from './app/users/modals/UserAuthHandler';
import UserAuthHandlerWide from './app/users/modals/UserAuthHandlerWide';
import UserOrderDetailPage from './app/users/pages/UserOrderDetailPage';
import UserPointsPage from './app/users/pages/UserPointsPage';
import UserReviewDetailPage from './app/users/pages/UserReviewDetailPage';
import UserReviewDetailPageComplete from './app/users/pages/UserReviewDetailPageComplete';
import UserReviewPage from './app/users/pages/UserReviewPage';
import UsersAddressPage from './app/users/pages/UsersAddressPage';
import UsersDashboardPage from './app/users/pages/UsersDashboardPage';
import UsersFamilyPage from './app/users/pages/UsersFamilyPage';
import UsersLogoutPage from './app/users/pages/UsersLogoutPage';
import UsersOrderPage from './app/users/pages/UsersOrderPage';
import UsersProfilePage from './app/users/pages/UsersProfilePage';
import UserSupportDetailPage from './app/users/pages/UserSupportDetailPage';
import UserSupportPage from './app/users/pages/UserSupportPage';
import UserVoucherPage from './app/users/pages/UserVoucherPage';
import UserWishlistPage from './app/users/pages/UserWishlistPage';
import AppInitDispatch from './dispatches/AppInitDispatch';
import EmptyPlaceholder from './placeholders/EmptyPlaceholder';
import InitInfo from './utils/InitInfo';

function RouteHandler() {
    const dispatch = useDispatch();
    const { initData } = InitInfo();
    const [show, setShow] = useState(!!initData?.initId);

    useEffect(() => {
        AppInitDispatch(dispatch)
            .then((initStatus) => {
                setShow(initStatus);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [dispatch]);

    if (!show) {
        return <EmptyPlaceholder />;
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<IndexPage />} />
                <Route path="/category/:categoryPath/" element={<CategoryPage />} />
                <Route path="/category/all/" element={<CategoryPageAll />} />
                <Route
                    path="/products/:productId/:productTitle/"
                    element={<ProductDetailsPage />}
                />
                <Route path="/search/" element={<SearchPage />} />

                <Route path="/" element={<UserAuthHandlerWide />}>
                    <Route path="cart/" element={<CartPage />} />
                    <Route path="checkout/" element={<CheckoutPage />} />
                    <Route path="invoice/:invoiceId/payment/" element={<PaymentPage />} />
                    <Route path="invoice/:invoiceId/view" element={<InvoicePage />} />
                    <Route path="order/:invoiceId/success" element={<OrderSuccessPage />} />
                </Route>

                <Route path="/user/*" element={<UserAuthHandler />}>
                    <Route path="dashboard/" element={<UsersDashboardPage />} />
                    <Route path="profile/" element={<UsersProfilePage />} />
                    <Route path="family-profile/" element={<UsersFamilyPage />} />
                    <Route path="address-profile/" element={<UsersAddressPage />} />
                    <Route path="orders/" element={<UsersDashboardPage />} />
                    <Route path="logout/" element={<UsersLogoutPage />} />
                    <Route path="order/" element={<UsersOrderPage type="all" />} />
                    <Route path="delivered-order/" element={<UsersOrderPage type="delivered" />} />
                    <Route path="shipped-order/" element={<UsersOrderPage type="shipped" />} />
                    <Route
                        path="processing-order/"
                        element={<UsersOrderPage type="processing" />}
                    />
                    <Route path="pending-order/" element={<UsersOrderPage type="pending" />} />
                    <Route path="canceled-order/" element={<UsersOrderPage type="canceled" />} />
                    <Route path="prepared-order/" element={<UsersOrderPage type="prepared" />} />
                    <Route path="refunded-order/" element={<UsersOrderPage type="refunded" />} />
                    <Route path="order-details/:invoiceId/" element={<UserOrderDetailPage />} />
                    <Route path="wishlist/" element={<UserWishlistPage />} />
                    <Route path="support/" element={<UserSupportPage />} />
                    <Route path="support/:ticketId/detail/" element={<UserSupportDetailPage />} />
                    <Route path="reviews/" element={<UserReviewPage type="pending" />} />
                    <Route path="reviews/history/" element={<UserReviewPage type="complete" />} />
                    <Route path="vouchers/" element={<UserVoucherPage />} />
                    <Route path="vouchers/applied/" element={<UserVoucherPage />} />
                    <Route path="vouchers/expired/" element={<UserVoucherPage />} />
                    <Route path="points/" element={<UserPointsPage />} />
                    <Route
                        path="reviews/:invoiceItemId/detail/"
                        element={<UserReviewDetailPage />}
                    />
                    <Route
                        path="reviews/:reviewId/detail/complete/"
                        element={<UserReviewDetailPageComplete />}
                    />
                </Route>
                <Route path="/about-us/" element={<AboutUsPage />} />
                <Route path="/terms-condition/" element={<TermsPage />} />
            </Routes>
        </BrowserRouter>
    );
}

export default RouteHandler;
