function ObjIsEqual(obj1, obj2) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (let x = 0; x < keys1.length; x += 1) {
        const key = keys1[x];

        if (obj1[key] !== obj2[key]) {
            return false;
        }
    }

    return true;
}

export function searchEqualObject(arr, obj) {
    const keys = Object.keys(obj);

    for (let x = 0; x < arr.length; x += 1) {
        const newObj = {};
        for (let y = 0; y < keys.length; y += 1) {
            newObj[keys[y]] = arr[x][keys[y]];
        }

        if (ObjIsEqual(obj, newObj)) {
            return arr[x];
        }
    }

    return null;
}

export default ObjIsEqual;
