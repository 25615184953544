import React from 'react';
import { Link } from 'react-router-dom';
import Iopt from '../Iopt';
import './effectOne.scss';

export default function EffectOne(props) {
    const { data } = props;
    return (
        <div className="portfolio-effect">
            <div className="portfolio-item portfolio-effect__item portfolio-item--eff4">
                <img
                    className="portfolio-item__image"
                    src={data ? Iopt(data.image_url, 360) : ''}
                    alt=""
                />

                <Link
                    to={`category/${data.category_title.replace(' ', '-')}-${data.categoryId}`}
                    className="portfolio-item__info"
                >
                    <h3 className="portfolio-item__header">{data ? data.category_title : ''}</h3>

                    <div className="portfolio-item__links">
                        <button type="button">SHOP NOW</button>
                    </div>
                </Link>
            </div>
        </div>
    );
}
