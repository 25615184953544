import React, { createContext, useContext, useEffect, useState } from 'react';
import ConfigApi from '../../configs/ConfigApi';
import AxiosAuth from '../utils/AxiosAuth';
import ObjIsEqual from '../utils/ObjIsEqual';
import { useAuth } from './AuthProvider';

const CartContext = createContext();

export function useCart() {
    return useContext(CartContext);
}

const uploadCartData = (currentUser, cartItems, setCartData) =>
    new Promise((resolve, reject) => {
        AxiosAuth.currentUserAuth(currentUser)
            .post(ConfigApi.API_CART_STATE_UPDATE, {
                shopId: process.env.REACT_APP_SHOP_ID,
                newState: cartItems.map((item) => ({
                    productId: item.productId,
                    productVariation: item.productVariation,
                })),
            })
            .then(({ data }) => {
                resolve({ data });

                if (data.error === 0) {
                    setCartData(data.cartData);
                }
            })
            .catch((err) => {
                reject(err);
            });
    });

function CartProvider({ children }) {
    const { currentUser } = useAuth();
    const [updateCart, setUpdateCart] = useState(null);
    const [cartData, setCartData] = useState(null);
    const cartItems = cartData?.cartItems || [];
    const subTotal = cartData?.subTotal || 0;
    const totalSave = cartData?.totalSave || 0;
    const shippingCharge = cartData?.shippingCharge || 0;
    const cartDataLoaded = cartData?.error === 0;

    const setCartItems = (items) => {
        setCartData((exState) => ({
            ...exState,
            cartItems: items,
        }));
    };

    const addToCart = (productId, selectedVariation, newQuantity, isSelected = true) => {
        const index = cartItems.findIndex(
            (item) =>
                item.productId === productId && ObjIsEqual(selectedVariation, item.productVariation)
        );

        const newCartItems = [...cartItems];

        if (index >= 0) {
            newCartItems[index].productVariation = {
                ...newCartItems[index].productVariation,
                quantity: newQuantity,
            };
        } else {
            // New Product
            newCartItems.push({
                productId,
                productVariation: { ...selectedVariation, quantity: newQuantity || 1, isSelected },
            });
        }

        // setCartItems(newCartItems);
        uploadCartData(currentUser, newCartItems, setCartData);
    };

    const makeAsSelected = (productId, selectedVariation, isSelected) => {
        const index = cartItems.findIndex(
            (item) =>
                item.productId === productId && ObjIsEqual(selectedVariation, item.productVariation)
        );

        const newCartItems = [...cartItems];

        if (index >= 0) {
            newCartItems[index].productVariation = {
                ...newCartItems[index].productVariation,
                isSelected,
            };
        }

        // setCartItems(newCartItems);
        uploadCartData(currentUser, newCartItems, setCartData);
    };

    const emptyCart = () => {
        uploadCartData(currentUser, [], setCartData);
    };

    const forceUpdateCart = () => {
        setUpdateCart(Math.random());
    };

    useEffect(() => {
        if (currentUser?.userFound) {
            AxiosAuth.currentUserAuth(currentUser)
                .get(ConfigApi.API_CART)
                .then(({ data }) => {
                    if (data.error === 0) {
                        setCartData(data.cartData);
                    } else {
                        setCartData([]);
                    }
                });
        }
    }, [currentUser, updateCart]);

    const totalItem =
        cartItems?.reduce((cVal, item) => cVal + item.productVariation.quantity, 0) || 0;

    const value = {
        cartItems,
        setCartItems,
        totalItem,
        subTotal,
        totalSave,
        shippingCharge,
        addToCart,
        makeAsSelected,
        emptyCart,
        forceUpdateCart,
        cartDataLoaded,
    };

    return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
}

export default CartProvider;
