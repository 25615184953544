/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Button, Form } from 'react-bootstrap';

function LoginOtpBody({
    signInWithGoogle,
    signInWithFacebook,
    signInWithTwitter,
    openRegistrationModal,
}) {
    const handelPhoneLogin = () => {
        //
    };

    return (
        <>
            <Form>
                <Form.Group className="custom-login-input">
                    <Form.Label className="custom-label">OTP</Form.Label>
                    <Form.Control className="custom-input" type="email" placeholder="Enter email" />
                </Form.Group>

                <Form.Group className="custom-login-input">
                    <Form.Label className="custom-label">Password</Form.Label>
                    <Form.Control className="custom-input" type="password" placeholder="Password" />
                </Form.Group>

                <Form.Group className="custom-login-other">
                    <Form.Check
                        inline
                        label="Remember Me"
                        className="custom-login-other-label"
                        name="group1"
                        type="checkbox"
                    />
                    {/* <Link to="/forgot">Forgot Password ?</Link> */}
                </Form.Group>

                <Button
                    variant="primary"
                    type="submit"
                    className="custom-login-btn"
                    onClick={handelPhoneLogin}
                >
                    Login
                </Button>
                <Form.Group className="custom-login-other" style={{ textAlign: 'center' }}>
                    <p>or</p>
                </Form.Group>
            </Form>

            <Form.Group className="custom-login-social">
                <Button
                    type="button"
                    onClick={() => {
                        signInWithGoogle();
                    }}
                    className="custom-login-btn-google"
                >
                    Google
                </Button>
                <Button
                    type="button"
                    className="custom-login-btn-facebook"
                    onClick={signInWithFacebook}
                >
                    Facebook
                </Button>
                <Button
                    type="button"
                    className="custom-login-btn-twitter"
                    onClick={signInWithTwitter}
                >
                    Twitter
                </Button>
            </Form.Group>

            <Form.Group className="custom-login-footer">
                <p>
                    Don&apos;t have an account?{' '}
                    <span
                        onClick={() => {
                            openRegistrationModal(false);
                        }}
                    >
                        Register Now
                    </span>
                </p>
            </Form.Group>
        </>
    );
}

export default LoginOtpBody;
