import React from 'react';
import StockOutIcon from '../../assets/images/stock-out.svg';

const typeBg = {
    new: '#00B59C',
    trending: '#FFA113',
    hot: '#FF5B14',
};

function CustomBadge({ type }) {
    if (type === 'stock_out') {
        return (
            <div className="stock-out-badge">
                <img src={StockOutIcon} alt="" />
            </div>
        );
    }
    return (
        <span className="product-badge-new" style={{ background: typeBg[type] }}>
            {type}
        </span>
    );
}

export default CustomBadge;
