import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ConfigApi from '../../../../configs/ConfigApi';
import { useAuth } from '../../../contexts/AuthProvider';
import { useTemplate } from '../../../contexts/TemplateProvider';
import AxiosAuth from '../../../utils/AxiosAuth';
import PageProperties from '../../../utils/PageProperties';
import ReactGA4 from '../../../utils/ReactGA4';
import SupportCompleteModal from '../components/SupportCompleteModal';
import SupportDetailSection from '../components/SupportDetailSection';

const getSupportData = (params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.currentUserAuth(params.currentUser)
            .get(ConfigApi.API_SUPPORT)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
const getSupportTicketData = (params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.currentUserAuth(params.currentUser)
            .get(ConfigApi.API_SUPPORT_TICKET_MESSAGE.replace(':ticketId', params.ticketId), params)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

const completingTicket = (params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.currentUserAuth(params.currentUser)
            .post(
                ConfigApi.API_SUPPORT_TICKET_COMPLETE.replace(':ticketId', params.ticketId),
                params
            )
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

function UserSupportDetailPage() {
    const history = useNavigate();
    const { setMessage } = useTemplate();
    const { ticketId } = useParams();
    const { currentUser } = useAuth();
    const [supportData, setSupportData] = useState([]);
    const [supportTicketMessageData, setSupportTicketMessageData] = useState([]);
    const [messageText, setMessageText] = useState('');
    const [messageFile, setMessageFile] = useState('');
    const [completeModalShow, setCompleteModalShow] = useState(false);
    const [isSolved, setIsSolved] = useState(1);
    const [comment, setComment] = useState('');
    const [rating, setRating] = useState(0);

    const handleReviewValueChange = (event) => {
        if (event.target.name === 'isSolved') {
            setIsSolved(event.target.value);
        }
        if (event.target.name === 'comment') {
            setComment(event.target.value);
        }
        if (event.target.name === 'rating') {
            setRating(event.target.value);
        }
    };

    const handleMessage = (event) => {
        setMessageText(event.target.value);
    };
    const handleRemoveFile = () => {
        setMessageFile('');
        setMessageText('');
    };

    const handleOnFileChange = async ({ target }) => {
        const { files } = target;
        for (let x = 0; x < files.length; x += 1) {
            const formData = new FormData();
            formData.append('image', files[x]);
            AxiosAuth.currentUserAuth(currentUser)
                .post(ConfigApi.API_IMAGE_UPLOAD, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then((response) => {
                    setMessage(response.data.message);
                    setMessageFile(response.data.imageUrl);
                    setMessageText(response.data.imageName);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };
    const sendMessage = (e) => {
        e.preventDefault();

        AxiosAuth.currentUserAuth(currentUser)
            .post(ConfigApi.API_SUPPORT_TICKET_MESSAGE_CREATE, {
                ticketId,
                message: messageText,
                messageFile,
            })
            .then((response) => {
                setMessage(response.data.message);

                if (response.data.error === 0) {
                    setSupportTicketMessageData(response.data.ticketMessages);
                    setMessageText('');
                    setMessageFile('');
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const completeTicket = (e) => {
        e.preventDefault();
        completingTicket({
            ticketId,
            isSolved,
            comment,
            rating,
            currentUser,
        })
            .then((response) => {
                setMessage(response.data.message);

                if (response.data.error === 0) {
                    history(`/user/support/`);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    useEffect(() => {
        setMessageFile('');
        setMessageText('');
        getSupportData({ currentUser })
            .then((response) => {
                setSupportData(response.data.supportAr);
            })
            .catch((err) => {
                console.log(err);
            });
        getSupportTicketData({ ticketId, currentUser })
            .then((response) => {
                setSupportTicketMessageData(response.data.ticketMessages);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [currentUser, ticketId]);
    PageProperties('Support');
    return (
        <>
            <ReactGA4 />
            <div className="col-12 col-lg-9">
                <SupportDetailSection
                    supportData={supportData}
                    supportTicketMessageData={supportTicketMessageData}
                    handleMessage={handleMessage}
                    message={messageText}
                    sendMessage={sendMessage}
                    handleOnFileChange={handleOnFileChange}
                    messageFile={messageFile}
                    handleRemoveFile={handleRemoveFile}
                    completeTicket={completeTicket}
                    setCompleteModalShow={setCompleteModalShow}
                />
                <SupportCompleteModal
                    modalShow={completeModalShow}
                    setCompleteModalShow={setCompleteModalShow}
                    completeTicket={completeTicket}
                    isSolved={isSolved}
                    comment={comment}
                    rating={rating}
                    handleReviewValueChange={handleReviewValueChange}
                />
            </div>
        </>
    );
}
export default UserSupportDetailPage;
