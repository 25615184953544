import React, { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ConfigApi from '../../configs/ConfigApi';
import { useAuth } from '../contexts/AuthProvider';
import { useTemplate } from '../contexts/TemplateProvider';
import AxiosAuth from '../utils/AxiosAuth';

const Cod = forwardRef((props, ref) => {
    const { order } = props; // , amount, currency
    const { currentUser } = useAuth();
    const { setMessage } = useTemplate();
    const navigate = useNavigate();

    const handelOnClick = () => {
        AxiosAuth.currentUserAuth(currentUser)
            .post(ConfigApi.COD_PAYMENT.replace(':invoiceId', order))
            .then(({ data }) => {
                setMessage(data.message);

                if (data.error === 0) {
                    navigate(`/order/${order}/success/`);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <button
            type="button"
            style={{ display: 'none' }}
            id="cod"
            ref={ref}
            order={order}
            onClick={handelOnClick}
        />
    );
});

export default Cod;
