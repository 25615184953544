/* eslint-disable jsx-a11y/no-static-element-interactions */
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthProvider';
import Iopt from '../../../utils/Iopt';
import OptionBox from '../../../utils/OptionBox';
import UserInfo from './UserInfo';

function UserOption({ setShowLoginModal, isUserPage }) {
    const { isSignIn, currentUser, openLoginModal } = useAuth();
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const ref = useRef();

    const handleHide = () => {
        setShow(false);
    };

    useEffect(() => {
        if (setShowLoginModal) {
            ref.current.click();
        }
    }, [setShowLoginModal]);

    return (
        <div className="top-options">
            {!isSignIn ? (
                <span
                    ref={ref}
                    className={['top-icon', show ? 'box-showing' : ''].join(' ')}
                    onClick={() => {
                        openLoginModal(null, () => {
                            if (isUserPage) {
                                navigate('/');
                            }
                        });
                    }}
                >
                    <FontAwesomeIcon icon={faUser} size="lg" className="user-operation-icon" />
                    <div className="header-acc-title">Login</div>
                </span>
            ) : (
                <div
                    role="button"
                    tabIndex={-1}
                    className={['top-icon', show ? 'box-showing' : ''].join(' ')}
                    onClick={() => {
                        setShow(true);
                    }}
                >
                    {currentUser?.photoUrl ? (
                        <img
                            src={Iopt(currentUser?.photoUrl, 128)}
                            alt=""
                            style={{ width: 30, height: 30, borderRadius: '50%' }}
                        />
                    ) : (
                        <span
                            style={{
                                borderRadius: '50%',
                                backgroundColor: 'red',
                                paddingTop: 2,
                                color: 'white',
                                fontWeight: 'bold',
                            }}
                        >
                            {currentUser?.displayName ? currentUser?.displayName.slice(0, 1) : '##'}
                        </span>
                    )}

                    <div className="header-acc-title">Account</div>
                </div>
            )}

            <OptionBox show={show} handleHide={handleHide}>
                <UserInfo userInformation={currentUser} />
            </OptionBox>
        </div>
    );
}

export default UserOption;
