import React from 'react';
import { Button, Card, Container, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../../../contexts/CartProvider';
import CartProducts from './CartProducts';

function CartSummary({ totalItem, totalPrice, cartItems }) {
    const history = useNavigate();
    const handleProceed = () => {
        history('/checkout/');
    };
    return (
        <div className="col-12 col-lg-4">
            <Card>
                <Card.Body>
                    <h6>Order Summary</h6>
                    <div className="cart-section-summary">
                        <Table>
                            <tbody>
                                <tr>
                                    <td>Subtotal ({totalItem} items)</td>
                                    <td className="text-right">৳ {totalPrice.toFixed(2)}</td>
                                </tr>

                                <tr style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                    <td>Total</td>
                                    <td className="text-right">৳ {totalPrice.toFixed(2)}</td>
                                </tr>
                            </tbody>
                        </Table>
                        <Button
                            style={{
                                pointerEvents: cartItems.length <= 0 ? 'none' : '',
                            }}
                            className="proceed-to-pay"
                            onClick={handleProceed}
                        >
                            Proceed to Checkout
                        </Button>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}

function CartPageSection({ products }) {
    const { subTotal, totalItem, cartItems, emptyCart } = useCart();

    const handleEmptyCart = () => {
        emptyCart();
    };

    return (
        <section className="cart-section cart-section2">
            <Container>
                <div className="row mt-3">
                    <CartProducts
                        cartItems={cartItems}
                        products={products}
                        totalPrice={subTotal}
                        handleEmptyCart={handleEmptyCart}
                    />
                    <CartSummary
                        totalPrice={subTotal}
                        totalItem={totalItem}
                        cartItems={cartItems}
                    />
                </div>
            </Container>
        </section>
    );
}

export default CartPageSection;
