/* eslint-disable no-unused-vars */
import React from 'react';
import LazyLoad from 'react-lazyload';
import ProductPageDataMulti from '../ProductItem';
import ProductPageSingle from '../ProductPageSingle';

function CategoryProducts({ itemData, myClass }) {
    return (
        <div
            className={[
                'col mb-4 col-m-product',
                myClass === 'product-section-colI1'
                    ? 'product-section-colI1'
                    : 'product-section-colI3',
            ].join(' ')}
        >
            {myClass === 'product-section-colI1' ? (
                <LazyLoad throttle={200} height={300}>
                    <ProductPageSingle itemData={itemData} />
                </LazyLoad>
            ) : (
                <LazyLoad throttle={200} height={300}>
                    <ProductPageDataMulti itemData={itemData} />
                </LazyLoad>
            )}
        </div>
    );
}

export default CategoryProducts;
