/* eslint-disable no-unused-vars */
import { faList, faTh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-bootstrap';

function CategoryPageFilter({ myClass, handleColumOne, handleColumThree }) {
    return (
        <div className="col-12 col-m-product">
            <div className="page-filter-view">
                <div className="pageFilter">
                    <div className="page-filter-option">
                        <div className=" btn-group">
                            <Button
                                size="sm"
                                active={myClass === 'product-section-colI1'}
                                variant="outline-danger"
                                onClick={handleColumOne}
                            >
                                <FontAwesomeIcon icon={faList} />
                            </Button>
                            <Button
                                size="sm "
                                active={myClass === 'product-section-colI3'}
                                variant="outline-danger"
                                onClick={handleColumThree}
                            >
                                <FontAwesomeIcon icon={faTh} />
                            </Button>
                        </div>
                        <div className="btn-group" style={{ float: 'right' }}>
                            <Button size="sm " variant="outline-danger">
                                Best Sellers
                            </Button>
                            <Button size="sm " variant="outline-danger">
                                Newest
                            </Button>
                            <Button size="sm " variant="outline-danger">
                                Top Rated
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CategoryPageFilter;
