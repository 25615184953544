import React from 'react';
import InitInfo from '../../../utils/InitInfo';
import PageProperties from '../../../utils/PageProperties';
import ReactGA4 from '../../../utils/ReactGA4';
import CartPageSection from '../components/CartPageSection';

function CartPage() {
    const { products } = InitInfo();

    PageProperties('Cart');

    return (
        <>
            <ReactGA4 />
            <CartPageSection products={products} />
        </>
    );
}

export default CartPage;
