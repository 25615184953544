import React, { useEffect, useState } from 'react';
import ConfigApi from '../../../../configs/ConfigApi';
import { useAuth } from '../../../contexts/AuthProvider';
import AxiosAuth from '../../../utils/AxiosAuth';
import InitInfo from '../../../utils/InitInfo';
import PageProperties from '../../../utils/PageProperties';
import ReactGA4 from '../../../utils/ReactGA4';
import CheckoutPageSection from '../components/CheckoutPageSection';

function CheckoutPage() {
    const { products } = InitInfo();
    const { currentUser } = useAuth();
    const [addressData, setAddressData] = useState(null);

    useEffect(() => {
        if (currentUser) {
            AxiosAuth.currentUserAuth(currentUser)
                .get(ConfigApi.API_USER_ADDRESS)
                .then(({ data }) => {
                    if (data.error === 0) {
                        setAddressData(data);
                    }
                });
        }
    }, [currentUser]);

    if (!currentUser) {
        // --No User Placeholder
        return <div>Logging in...</div>;
    }

    if (!addressData) {
        // --Error Data Placeholder
        return <div>Loading Page Information...</div>;
    }
    PageProperties('Checkout');

    return (
        <>
            <ReactGA4 />
            <CheckoutPageSection
                products={products}
                currentUser={currentUser}
                addressData={addressData}
                setAddressData={setAddressData}
            />
        </>
    );
}

export default CheckoutPage;
