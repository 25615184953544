import React from 'react';
import { Link } from 'react-router-dom';

function UserProfilePageTitle() {
    const location = window.location.pathname;
    return (
        <div className="user-profile-title">
            <div className="user-profile-title-view">
                <Link
                    to="/user/profile/"
                    className={location === '/user/profile/' ? 'user-profile-btn-active' : ''}
                >
                    My Profile
                </Link>
                <Link
                    to="/user/family-profile/"
                    className={
                        location === '/user/family-profile/' ? 'user-profile-btn-active' : ''
                    }
                >
                    Family Member
                </Link>
                <Link
                    to="/user/address-profile/"
                    className={
                        location === '/user/address-profile/' ? 'user-profile-btn-active' : ''
                    }
                >
                    Address Book
                </Link>
            </div>
        </div>
    );
}

export default UserProfilePageTitle;
