import { faMinus, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import NoProductImg from '../../../../assets/images/NoProduct2.png';
import { useCart } from '../../../contexts/CartProvider';
import FindInArray from '../../../utils/FindInArray';

function ProductSize({ size }) {
    return (
        <>
            <button type="button" className="cart-detail-sizeBtn">
                {size}
            </button>
        </>
    );
}

function ProductAgeRange({ age }) {
    return (
        <button type="button" className="cart-detail-ageBtn">
            {age}
        </button>
    );
}

function ProductColor({ color }) {
    return (
        <>
            <button
                type="button"
                style={{
                    backgroundColor: color,
                }}
                className="cart-color-item-bg"
            />
        </>
    );
}

function ProductVariantOption({ itemName }) {
    return (
        <>
            {itemName.color ? <ProductColor color={itemName.color} /> : ''}
            {itemName.size ? <ProductSize size={itemName.size} /> : ''}
            {itemName.ageRange ? <ProductAgeRange age={itemName.ageRange} /> : ''}
        </>
    );
}

function CartProductVariant({ data }) {
    if (data) {
        return <ProductVariantOption itemName={data} />;
    }

    return null;
}

function QuantityBtn({ addToCart, id, pQty, selectedVariation }) {
    const [newQuantity, setNewQuantity] = useState(pQty);

    const handleQtyPlus = () => {
        setNewQuantity(newQuantity + 1);
        addToCart(id, selectedVariation, newQuantity + 1, true);
    };

    const handleQtyMinus = () => {
        if (newQuantity <= 1) {
            setNewQuantity(1);
        } else {
            setNewQuantity(newQuantity - 1);
            addToCart(id, selectedVariation, newQuantity - 1, true);
        }
    };

    const handleChange = (e) => {
        setNewQuantity(e.target.value);
    };

    return (
        <>
            <FontAwesomeIcon onClick={handleQtyMinus} size="sm" icon={faMinus} />
            <input min={1} onChange={handleChange} value={newQuantity} />
            <FontAwesomeIcon onClick={handleQtyPlus} size="sm" icon={faPlus} />
        </>
    );
}

function CartProduct({ myItem: item, variation }) {
    const { addToCart, makeAsSelected } = useCart();

    const handleRemove = () => {
        addToCart(item.id, variation, 0, false);
    };

    const handleProductSelect = (ev) => {
        makeAsSelected(item.id, variation, ev.target.checked);
    };

    if (variation.quantity < 1) {
        return null;
    }

    return (
        <div className="cart-product-list row">
            <div className="col-1 cart-product-flex cart-qty cart-checkbox">
                <input
                    type="checkbox"
                    onChange={handleProductSelect}
                    checked={variation.isSelected}
                />
            </div>

            <div className="col-1 cart-product-flex cart-qty">
                <div className="cart-section-product-img">
                    <img src={item.default_image} alt="" />
                </div>
            </div>

            <div className="col-4 cart-product-flex" style={{ textAlign: 'left' }}>
                <Link to={`/products/${item.id}/${item.title.replace(/[^\w\\s]/gi, '-')}`}>
                    <b>{item.title}</b>
                </Link>
                <br />
                <div className="variant-list-cart">
                    <CartProductVariant data={variation} />
                </div>
            </div>

            <div className="col-2 cart-product-flex cart-qty" style={{ textAlign: 'center' }}>
                <small style={{ width: 100 }}>{item.priceOffer.toFixed(2)}</small>
            </div>

            <div className="col-2 cart-product-fle cart-qty">
                <div className="cart-product-qty">
                    <QuantityBtn
                        id={item.id}
                        pQty={variation.quantity}
                        selectedVariation={variation}
                        addToCart={addToCart}
                    />
                </div>
            </div>

            <div className="col-2 cart-product-flex cart-qty">
                <div className="cart-product-dlt-btn">
                    {variation.isSelected ? (
                        (variation.quantity * item.priceOffer).toFixed(2)
                    ) : (
                        <FontAwesomeIcon
                            icon={faTrash}
                            color="red"
                            value={variation.quantity}
                            onClick={handleRemove}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

function CartData({ cartItems, products }) {
    const FindArr = new FindInArray();
    FindArr.init(products, 'id');

    return (
        <div className="cart-section-product-list">
            {cartItems.map((cartItem) => {
                const itemData = FindArr.find(cartItem.productId);
                const uKey = cartItem.hash || Math.random();

                return (
                    <CartProduct
                        myItem={itemData}
                        key={`${cartItem.productId}-${uKey}`}
                        variation={cartItem.productVariation}
                    />
                );
            })}
        </div>
    );
}

function CartProducts({ cartItems, products, handleEmptyCart }) {
    return (
        <div className="col-12 col-lg-8 mb-4">
            <Card className="cart-section-card">
                <Card.Body>
                    <div className="cart-section-product">
                        <div className="cart-section-product-top">
                            <h6>Cart Products</h6>
                            <button type="button" onClick={handleEmptyCart}>
                                Empty Cart
                            </button>
                        </div>
                        {cartItems.length !== 0 ? (
                            <CartData products={products} cartItems={cartItems} />
                        ) : (
                            <div className="order-section-no-order mt-4">
                                <div className="order-section-no-order-title">
                                    <h5>There Are No Cart Products.</h5>
                                    <h6>You can see cart products here.</h6>
                                </div>
                                <div className="order-section-no-order-img">
                                    <img src={NoProductImg} alt="No Product" />
                                </div>
                            </div>
                        )}
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}

export default CartProducts;
