import React from 'react';
import ImageGallery from 'react-image-gallery';
import '../../../../../../node_modules/react-image-gallery/styles/css/image-gallery.css';
import PlaceholderImg from '../../../../../assets/images/placeholder-image.png';

// original: `https://www.optimizee.xyz/images/${btoa(imgUrl?.id)},200,300.jpg`,
// thumbnail: `https://www.optimizee.xyz/images/${btoa(imgUrl?.id)},200,300.jpg`,
//                thumbnail: `https://store-api.bikiran.com/uploads/${imgUrl.id}`,

export default function ImageSliderComponent({ thumbImages }) {
    const images = [];
    if (thumbImages.length > 0) {
        thumbImages.map((imgUrl) =>
            images.push({
                original: `https://www.optimizee.xyz/images/${btoa(
                    `https://store-api.bikiran.com/uploads/${imgUrl.id}`
                )},200,300.jpg`,
                thumbnail: `https://www.optimizee.xyz/images/${btoa(
                    `https://store-api.bikiran.com/uploads/${imgUrl.id}`
                )},200,300.jpg`,
            })
        );
    } else {
        images.push({
            original: PlaceholderImg,
            thumbnail: PlaceholderImg,
        });
    }

    return (
        <div className="product-detail-imgBox">
            <ImageGallery slideDuration={1000} items={images} />
        </div>
    );
}
