import React from 'react';
import { Modal } from 'react-bootstrap';
import LoginFormBody from './LoginFormBody';
import LoginOtpBody from './LoginOtpBody';

function UserLoginModal({
    signIn,
    signInWithGoogle,
    signInWithFacebook,
    signInWithTwitter,
    show,
    setShow,
    onCloseClick,
    showOtp,
    openRegistrationModal,
    openForgetPasswordModal,
}) {
    return (
        <Modal
            size="md"
            show={show}
            onHide={() => {
                setShow(false);

                if (typeof onCloseClick === 'function') {
                    onCloseClick();
                }
            }}
            centered
            aria-labelledby="example-modal-sizes-title-lg"
            contentClassName="custom-login-content btn-close-custom"
        >
            <Modal.Header closeButton>
                <Modal.Title>User Login</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {!showOtp ? (
                    <LoginFormBody
                        signIn={signIn}
                        signInWithGoogle={signInWithGoogle}
                        signInWithFacebook={signInWithFacebook}
                        signInWithTwitter={signInWithTwitter}
                        setShow={setShow}
                        openRegistrationModal={openRegistrationModal}
                        openForgetPasswordModal={openForgetPasswordModal}
                    />
                ) : (
                    <LoginOtpBody
                        signInWithGoogle={signInWithGoogle}
                        signInWithFacebook={signInWithFacebook}
                        signInWithTwitter={signInWithTwitter}
                        setShow={setShow}
                        openRegistrationModal={openRegistrationModal}
                    />
                )}
            </Modal.Body>
        </Modal>
    );
}

export default UserLoginModal;
