import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import RegistrationFormBody from './RegistrationFormBody';
import RegistrationOtpBody from './RegistrationOtpBody';

function UserRegistrationModal({
    signIn,
    signUp,
    updateProfile,
    signInWithGoogle,
    signInWithFacebook,
    signInWithTwitter,
    show,
    setShow,
    onCloseClick,
    openLoginModal,
}) {
    const [optConfig, setOptConfig] = useState(null);

    return (
        <Modal
            size="md"
            show={show}
            onHide={() => {
                setShow(false);
                setOptConfig(null);

                if (typeof onCloseClick === 'function') {
                    onCloseClick();
                }
            }}
            centered
            aria-labelledby="example-modal-sizes-title-lg"
            contentClassName="custom-login-content custom-registration-content btn-close-custom"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {optConfig === null ? 'User Registration' : 'OTP Verification'}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {optConfig === null ? (
                    <RegistrationFormBody
                        signUp={signUp}
                        signInWithGoogle={signInWithGoogle}
                        signInWithFacebook={signInWithFacebook}
                        signInWithTwitter={signInWithTwitter}
                        setShow={setShow}
                        openLoginModal={openLoginModal}
                        setOptConfig={setOptConfig}
                    />
                ) : (
                    <RegistrationOtpBody
                        signIn={signIn}
                        signUp={signUp}
                        updateProfile={updateProfile}
                        setShow={setShow}
                        openLoginModal={openLoginModal}
                        optConfig={optConfig}
                        setOptConfig={setOptConfig}
                    />
                )}
            </Modal.Body>
        </Modal>
    );
}

export default UserRegistrationModal;
