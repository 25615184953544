import React from 'react';
import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthProvider';
import Footer from '../../system/components/Footer';
// import BrowserInfo from '../../../utils/BrowserInfo';
import HeaderSection from '../../system/components/HeaderSection';
import UserMenu from '../components/UserMenu';

function UserAuthHandler() {
    const { currentUser, isSignIn } = useAuth();
    // const browserInfo = BrowserInfo();

    if (currentUser === null) {
        // return <div>Please Login</div>;
        return <div>Loading User Information ...</div>;
    }

    if (!isSignIn) {
        return <HeaderSection whiteMenu />;
    }

    return (
        <>
            <HeaderSection whiteMenu />

            <section className="user-section">
                <Container>
                    <div className="row">
                        <UserMenu />
                        <Outlet />
                    </div>
                </Container>
            </section>

            <Footer display="block" />
        </>
    );
}

export default UserAuthHandler;
