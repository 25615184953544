import React from 'react';
import { Modal } from 'react-bootstrap';
import ForgetPassFormBody from './ForgetPassFormBody';

function ForgetPasswordModal({ show, setShow, returnToLogin, onCloseClick }) {
    return (
        <Modal
            size="md"
            show={show}
            onHide={() => {
                setShow(false);

                if (typeof onCloseClick === 'function') {
                    onCloseClick();
                }
            }}
            centered
            aria-labelledby="example-modal-sizes-title-lg"
            contentClassName="custom-login-content btn-close-custom"
        >
            <Modal.Header closeButton>
                <Modal.Title>Reset Password</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <ForgetPassFormBody setShow={setShow} returnToLogin={returnToLogin} />
            </Modal.Body>
        </Modal>
    );
}

export default ForgetPasswordModal;
