import React from 'react';
import { Button, Container } from 'react-bootstrap';
import FindInArray from '../../../utils/FindInArray';
import ProductItem from '../../products/components/ProductItem';

function FlashCount({ hour, min, sec }) {
    return (
        <>
            <Button variant="danger" className="product-detail-colorBtn">
                {hour}
            </Button>
            <Button variant="danger" className="product-detail-colorBtn">
                {min}
            </Button>
            <Button variant="danger" className="product-detail-colorBtn">
                {sec}
            </Button>
        </>
    );
}
function CampaignList({ campList: { campaignProducts }, productData }) {
    if (productData) {
        const FindArr = new FindInArray();
        FindArr.init(productData, 'id');

        return (
            <>
                {campaignProducts.map((item) => {
                    const itemData = FindArr.find(item.product_sl);
                    console.log(itemData);
                    if (itemData) {
                        return (
                            <div
                                className="col mb-4 col-m-product top-sell-product-section-colI5"
                                key={`${item.product_sl}`}
                            >
                                <ProductItem
                                    itemData={itemData}
                                    discount={campaignProducts.discount_percent}
                                />
                            </div>
                        );
                    }
                    return null;
                })}
            </>
        );
    }
    return null;
}
export default function FlashSaleProductSection({ list, productData }) {
    return (
        <section className="flash-product-section">
            <Container>
                <div className="row">
                    <div className="col-5 col-lg-2 col-xl-2 col-md-2">
                        <h4 className="custom-typo">
                            <span>{list.campaign_title}</span>
                        </h4>
                    </div>

                    <div className="col-7 col-lg-6 col-xl-8 col-md-8">
                        <div className="ends-title">
                            <span>
                                Ending in{' '}
                                <FlashCount hour={list.hour} min={list.min} sec={list.sec} />
                            </span>
                        </div>
                    </div>

                    <div className="col-12 col-lg-2 col-xl-2 col-md-2">
                        <div className="view-all">
                            <span>
                                <Button size="sm pl-3 pr-3 btn-round-corner" variant="danger">
                                    View All
                                </Button>
                            </span>
                        </div>
                    </div>
                    <div className="col-12 flash-sale-banner">
                        <img src={list?.img_url} alt="" />
                    </div>
                </div>

                <div className="sm-padding">
                    <div className="row row-m-product">
                        <CampaignList campList={list} productData={productData} />
                    </div>
                </div>
            </Container>
        </section>
    );
}
