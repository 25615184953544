import React from 'react';
import { Link } from 'react-router-dom';
import ReactGA4 from '../../../utils/ReactGA4';

function UserProfilePageTitle() {
    const location = window.location.pathname;
    return (
        <div className="user-profile-title">
            <div className="user-profile-title-view">
                <Link
                    to="/user/vouchers/"
                    className={location === '/user/vouchers/' ? 'user-profile-btn-active' : ''}
                >
                    Available
                </Link>
                <Link
                    to="/user/vouchers/applied/"
                    className={
                        location === '/user/vouchers/applied/' ? 'user-profile-btn-active' : ''
                    }
                >
                    Applied
                </Link>
                <Link
                    to="/user/vouchers/expired/"
                    className={
                        location === '/user/vouchers/expired/' ? 'user-profile-btn-active' : ''
                    }
                >
                    Expired
                </Link>
            </div>
        </div>
    );
}
function UserVoucherPage() {
    return (
        <div className="col-12 col-lg-9">
            <ReactGA4 />
            <div className="user-profile">
                <UserProfilePageTitle />
                <div className="user-voucher-section user-voucher-section-pt-20">
                    <div className="row gx-3 gy-3">
                        <div className="col-12 col-lg-6 col-xl-6 col-md-6">
                            <div className="user-voucher-list">
                                <div className="user-voucher-list-amount">
                                    <h4>৳685</h4>
                                </div>
                                <div className="user-voucher-list-divider">
                                    <div className="top-half-circle" />
                                    <div className="user-voucher-list-divider-line">
                                        <div className="line" />
                                    </div>
                                    <div className="bottom-half-circle" />
                                </div>
                                <div className="user-voucher-list-detail">
                                    <div>
                                        <h5>৳685 off on ৳6850</h5>
                                        <h6>Get ৳685 off your order over ৳6850</h6>
                                        <p>Validity: 2021.09.06 - 2021.10.06</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 col-xl-6 col-md-6">
                            <div className="user-voucher-list">
                                <div className="user-voucher-list-amount" />
                                <div className="user-voucher-list-divider">
                                    <div className="top-half-circle" />
                                    <div className="user-voucher-list-divider-line" />
                                    <div className="bottom-half-circle" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserVoucherPage;
