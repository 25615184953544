import React, { useEffect, useRef } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import ConfigApi from '../../../../configs/ConfigApi';
import { useAuth } from '../../../contexts/AuthProvider';
import { useTemplate } from '../../../contexts/TemplateProvider';
import AxiosAuth from '../../../utils/AxiosAuth';

export default function FamilyAddModal({ item, show, setShow, setSelectFamilyMember }) {
    const { currentUser } = useAuth();

    const name = useRef();
    const birthDate = useRef();
    const contact = useRef();
    const relation = useRef();
    const familyId = item?.sl || '';
    const { setMessage } = useTemplate();

    const handleFamilySave = () => {
        AxiosAuth.currentUserAuth(currentUser)
            .post(ConfigApi.API_USER_ADD_FAMILY, {
                name: name.current.value,
                birthDate: birthDate.current.value,
                contact: contact.current.value,
                relation: relation.current.value,
            })
            .then((response) => {
                setMessage(response.data.message);
                if (response.data.error === 0) {
                    setShow(false);
                    setSelectFamilyMember(null);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handleFamilyUpdate = () => {
        AxiosAuth.currentUserAuth(currentUser)
            .post(ConfigApi.API_USER_UPDATE_FAMILY.replace(':familyId', familyId), {
                name: name.current.value,
                birthDate: birthDate.current.value,
                contact: contact.current.value,
                relation: relation.current.value,
            })
            .then((response) => {
                setMessage(response.data.message);
                if (response.data.error === 0) {
                    setShow(false);
                    setSelectFamilyMember(null);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    useEffect(() => {
        if (item) {
            name.current.value = item?.name || '';
            contact.current.value = item?.phone || '';
            relation.current.value = item?.relation || '';
            birthDate.current.value = item?.birth_date || '';
        }
    }, [item]);

    return (
        <Modal
            size="md"
            show={show}
            onHide={() => {
                setShow(false);
                setSelectFamilyMember(null);
            }}
            centered
            aria-labelledby="example-modal-sizes-title-lg"
            contentClassName="custom-login-content btn-close-custom"
        >
            <Modal.Header closeButton>
                <Modal.Title>{familyId ? 'Family Member Update' : 'Family Member Add'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="custom-login-input">
                        <Form.Label className="custom-label">Reation</Form.Label>
                        <Form.Control
                            className="custom-input"
                            type="text"
                            placeholder="Reation"
                            name="realtion"
                            ref={relation}
                        />
                    </Form.Group>
                    <Form.Group className="custom-login-input">
                        <Form.Label className="custom-label">Name</Form.Label>
                        <Form.Control
                            className="custom-input"
                            type="text"
                            placeholder="Name"
                            name="name"
                            ref={name}
                        />
                    </Form.Group>
                    <Form.Group className="custom-login-input">
                        <Form.Label className="custom-label">Contact</Form.Label>
                        <Form.Control
                            className="custom-input"
                            type="text"
                            placeholder="Contact"
                            name="contact"
                            ref={contact}
                        />
                    </Form.Group>
                    <Form.Group className="custom-login-input">
                        <Form.Label className="custom-label">Birth Date</Form.Label>
                        <Form.Control className="custom-input" type="date" ref={birthDate} />
                    </Form.Group>

                    <Button
                        variant="primary"
                        type="button"
                        onClick={familyId ? handleFamilyUpdate : handleFamilySave}
                        className="custom-login-btn"
                        name="birthDate"
                    >
                        Save
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}
