/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link } from 'react-router-dom';
import Logo from '../../../../assets/images/Rup-logo.svg';

const MainMenuSubSub = ({ subBubItem, menuData, setShowSideMenu }) => {
    const [subBubItemClass, setSubBubItemClass] = useState('');
    return (
        <li className="link-area" key={4}>
            <Link to={subBubItem.url} onClick={() => setShowSideMenu(false)} className="link-title">
                {subBubItem.title}
            </Link>
        </li>
    );
};
const MainMenuSub = ({ subItem, menuData, setShowSideMenu }) => {
    const [subItemClass, setSubItemClass] = useState(false);
    const isSubMenu = menuData?.filter((it) => it.parent_id === subItem.id).length;
    return (
        <li className="link-area" key={4}>
            {isSubMenu > 0 ? (
                <button
                    type="button"
                    className="link-title"
                    onClick={() => {
                        setSubItemClass(!subItemClass);
                    }}
                >
                    {subItem.title}
                </button>
            ) : (
                <Link
                    to={subItem.url}
                    className="link-title"
                    onClick={() => setShowSideMenu(false)}
                >
                    {subItem.title}
                </Link>
            )}

            <ul style={{ display: subItemClass ? 'block' : 'none' }}>
                {menuData?.map((subBubItem) => {
                    if (subBubItem.parent_id === subItem.id) {
                        return (
                            <MainMenuSubSub
                                subBubItem={subBubItem}
                                menuData={menuData}
                                key={subBubItem.id}
                                setShowSideMenu={setShowSideMenu}
                            />
                        );
                    }
                    return null;
                })}
            </ul>
        </li>
    );
};
const MainMenu = ({ mainItem, menuData, setShowSideMenu }) => {
    const [itemClass, setitemClass] = useState(false);
    const isSubMenu = menuData?.filter((it) => it.parent_id === mainItem.id).length;
    return (
        <li className="link-area" key={4}>
            {isSubMenu > 0 ? (
                <button
                    type="button"
                    className="link-title"
                    onClick={() => {
                        setitemClass(!itemClass);
                    }}
                >
                    {mainItem.title}
                </button>
            ) : (
                <Link
                    to={mainItem.url}
                    onClick={() => setShowSideMenu(false)}
                    className="link-title"
                    role="button"
                >
                    {mainItem.title}
                </Link>
            )}

            <ul style={{ display: itemClass ? 'block' : 'none' }}>
                {menuData?.map((subItem) => {
                    if (subItem.parent_id === mainItem.id) {
                        return (
                            <MainMenuSub subItem={subItem} menuData={menuData} key={subItem.id} />
                        );
                    }
                    return null;
                })}
            </ul>
        </li>
    );
};
function HeaderSideBarMenu({ categories, show, setShowSideMenu }) {
    return (
        <Modal
            show={show}
            onHide={() => {
                setShowSideMenu(false);
            }}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            size="xl"
            className="modal left "
        >
            <div className="modal-content">
                <Modal.Header
                    closeButton={
                        <button type="button" className="close btn btn-close">
                            <span aria-hidden="true">×</span>
                            <span className="sr-only">Close</span>
                        </button>
                    }
                >
                    <Modal.Title>
                        <img src={Logo} alt="Logo" style={{ width: 125 }} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <div className="sidebar-menu-tab">
                        <Tabs
                            defaultActiveKey="home"
                            transition={false}
                            id="noanim-tab-example"
                            className=" mb-3"
                        >
                            <Tab eventKey="home" title="MEN">
                                <ul className="mobile-menu">
                                    {categories?.map((mainItem) => {
                                        // console.log(mainItem);
                                        // if (mainItem.parent_id === 0) {
                                        //     return (
                                        //         <MainMenu
                                        //             mainItem={mainItem}
                                        //             menuData={categories}
                                        //             key={mainItem.id}
                                        //             setShowSideMenu={setShowSideMenu}
                                        //         />
                                        //     );
                                        // }
                                        if (mainItem.parent_id === 10000001) {
                                            return (
                                                <MainMenuSub
                                                    subItem={mainItem}
                                                    menuData={categories}
                                                    key={mainItem.id}
                                                />
                                            );
                                        }
                                        return null;
                                    })}
                                </ul>
                            </Tab>
                            <Tab eventKey="profile" title="WOMEN">
                                <ul className="mobile-menu">
                                    {categories?.map((mainItem) => {
                                        if (mainItem.parent_id === 10000021) {
                                            return (
                                                <MainMenuSub
                                                    subItem={mainItem}
                                                    menuData={categories}
                                                    key={mainItem.id}
                                                />
                                            );
                                        }
                                        return null;
                                    })}
                                </ul>
                            </Tab>
                            <Tab eventKey="contact" title="KIDS">
                                <ul className="mobile-menu">
                                    {categories?.map((mainItem) => {
                                        if (mainItem.parent_id === 10000022) {
                                            return (
                                                <MainMenuSub
                                                    subItem={mainItem}
                                                    menuData={categories}
                                                    key={mainItem.id}
                                                />
                                            );
                                        }
                                        return null;
                                    })}
                                </ul>
                            </Tab>
                        </Tabs>
                    </div>
                </Modal.Body>
            </div>
        </Modal>
    );
}

export default HeaderSideBarMenu;
