import React from 'react';
import InitInfo from '../../../utils/InitInfo';
import PageProperties from '../../../utils/PageProperties';
import ReactGA4 from '../../../utils/ReactGA4';
import BestSellProducts from '../components/BestSellProducts';
import ExclusiveProductSection from '../components/ExclusiveProductSection';
import FeatureProductSection from '../components/FeatureProductSection';
import FlashSaleProductSection from '../components/FlashSaleProductSection';
import Footer from '../components/Footer';
// General Parameter of Each Page
// 1. Title Set
// 2. Data Load (From DB) In a single File
// 3. Spinner until load Data
import HeaderSection from '../components/HeaderSection';
import NewArrivalProductSection from '../components/NewArrivalProductSection';
import SelectedProductSection from '../components/SelectedProductSection';
import SliderSection from '../components/SliderSection';

export default function IndexPage() {
    const { products, campaignList } = InitInfo();
    PageProperties('');

    return (
        <>
            <ReactGA4 />
            <HeaderSection whiteMenu={false} />

            <SliderSection />
            {campaignList &&
                campaignList.map((list) => (
                    <FlashSaleProductSection list={list} productData={products} key={list.id} />
                ))}
            <FeatureProductSection />

            <SelectedProductSection products={products} />

            <ExclusiveProductSection products={products} />

            <NewArrivalProductSection products={products} />

            <BestSellProducts products={products} />

            <Footer />
        </>
    );
}
