import store from '../store';

function InitInfo() {
    const storeStates = store.getState();

    return {
        storeStates,
        show: !!storeStates?.initData?.initId,
        initData: storeStates?.initData,
        initId: storeStates?.initData?.initId,
        locale: storeStates?.initData?.locale,
        country: storeStates?.initData?.locale?.country,
        currency: storeStates?.initData?.locale?.currency,
        language: storeStates?.initData?.locale?.language,
        shopData: storeStates?.shopData,
        shopId: storeStates?.shopData?.shopInfo?.sl,
        campaignList: storeStates?.shopData?.campaignList,
        featureCategoryOptions: storeStates?.shopData?.featureCategoryOptions,
        products: storeStates?.shopData?.products,
        productCategoryList: storeStates?.shopData?.categories,
        productDetails: storeStates?.productDetails,
        isPaymentInProduction: storeStates?.shopData?.payments?.isProduction,
        paymentOptions: storeStates?.shopData?.payments?.methods,
    };
}

export default InitInfo;
