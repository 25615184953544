import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FindInArray from '../../../utils/FindInArray';
import StatusBtn from '../../../utils/StatusBtn';

function SupportListDesktopView({ supportData, supportDepartment }) {
    const FindArr = new FindInArray();
    FindArr.init(supportDepartment, 'id');

    return (
        <table>
            <thead>
                <tr>
                    <th>Ticket ID</th>
                    <th>Subject</th>
                    <th>Department</th>
                    <th>Issue Date</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                {supportData &&
                    supportData?.map((support) => {
                        const department = FindArr.find(support.department_sl);

                        return (
                            <tr key={support.id}>
                                <td>
                                    <Link to={`/user/support/${support.id}/detail`}>
                                        {support.id}{' '}
                                    </Link>
                                </td>
                                <td>{support.subject}</td>
                                <td>{department.department}</td>

                                <td>{support.time_created}</td>
                                <td>
                                    <StatusBtn status={support.status} />
                                </td>
                            </tr>
                        );
                    })}
            </tbody>
        </table>
    );
}
// function SupportListMobileView({ supportData, supportDepartment }) {
//     const FindArr = new FindInArray();
//     FindArr.init(supportDepartment, 'id');
//     return (
//         <div style={{ padding: 10, border: '1px solid #0000003b' }}>
//             {supportData &&
//                 supportData.map((support) => {
//                     const department = FindArr.find(support.department_sl);
//                     return (
//                         <div className="row" key={support.id}>
//                             <div className=" col-12">{support.ticket}</div>
//                             <div className=" col-6">{support.subject}</div>
//                             <div className=" col-6">{department.department}</div>
//                             <div className=" col-6">{support.status}</div>
//                             <div className=" col-6">{support.time_created}</div>
//                         </div>
//                     );
//                 })}
//         </div>
//     );
// }

function UserSupportSection({ supportData, supportDepartment, setShow }) {
    return (
        <Card className="user-support-section">
            <Card.Body>
                <div className="user-support-title">
                    <h6>
                        SUPPORT TICKETS{' '}
                        <button
                            type="button"
                            className="support-create-btn"
                            onClick={() => setShow(true)}
                        >
                            Add Tickets
                        </button>
                    </h6>
                </div>
                <div className="user-support-body">
                    <SupportListDesktopView
                        supportData={supportData}
                        supportDepartment={supportDepartment}
                    />
                </div>
            </Card.Body>
        </Card>
    );
}

export default UserSupportSection;
