import { React, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../../../../assets/effects/EffectTwoThumb.css';
import ConfigApi from '../../../../configs/ConfigApi';
import AxiosAuth from '../../../utils/AxiosAuth';
import InitInfo from '../../../utils/InitInfo';
import PageProperties from '../../../utils/PageProperties';
import ReactGA4 from '../../../utils/ReactGA4';
import Footer from '../../system/components/Footer';
import HeaderSection from '../../system/components/HeaderSection';
import ProductDetailSection from '../components/ProductDetailSection';

function ProductDetailsPage() {
    const { shopData, productCategoryList } = InitInfo();
    const [productData, setProductData] = useState('');
    const { productId } = useParams();
    const thumbImages = productData?.images || [];

    useEffect(() => {
        AxiosAuth.get(ConfigApi.API_PRODUCT_DETAILS.replace(':productId', productId), {
            params: {
                shopId: process.env.REACT_APP_SHOP_ID,
            },
        })
            .then((response) => {
                setProductData(response.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [productId]);
    PageProperties(productData?.title || '');

    return (
        <>
            <ReactGA4 />
            <HeaderSection whiteMenu />
            <ProductDetailSection
                productData={productData}
                productCategoryList={productCategoryList || []}
                thumbImages={thumbImages}
                shopId={shopData?.shopInfo?.sl}
            />
            <Footer />
        </>
    );
}

export default ProductDetailsPage;
