/* eslint-disable no-unused-vars */
import React from 'react';
import { Form } from 'react-bootstrap';
import IconMinus from '../../../../../assets/images/IconMinus.svg';

function CategoryPageSidebar() {
    return (
        <div className="d-none d-lg-block d-xl-block col-lg-3 col-lg-3">
            <div className="category-product-sidebar">
                <div className="category-product-sidebar-title">Filter By</div>
                <div className="category-product-sidebar-cat">
                    <div className="category-product-sidebar-cat-hrader">
                        <p>Categories</p>
                        <button type="button">
                            <img src={IconMinus} alt="" />
                        </button>
                    </div>
                    <div className="category-product-sidebar-cat-detail">
                        <Form.Check
                            inline
                            label="Man"
                            name="group1"
                            type="checkbox"
                            className="custom-checkbox"
                        />
                        <Form.Check
                            inline
                            label="Woman"
                            name="group1"
                            type="checkbox"
                            className="custom-checkbox"
                        />
                        <Form.Check
                            inline
                            label="Kids"
                            name="group1"
                            type="checkbox"
                            className="custom-checkbox"
                        />
                    </div>
                </div>
                <div className="category-product-sidebar-cat">
                    <div className="category-product-sidebar-cat-hrader">
                        <p>Color</p>
                        <button type="button">
                            <img src={IconMinus} alt="" />
                        </button>
                    </div>
                    <div className="category-product-sidebar-cat-detail">
                        <Form.Check aria-label="radio 1" className="custom-radio">
                            <Form.Check.Input type="radio" style={{ backgroundColor: 'red' }} />
                            <Form.Check.Label>Red</Form.Check.Label>
                        </Form.Check>
                        <Form.Check aria-label="radio 1" className="custom-radio">
                            <Form.Check.Input type="radio" style={{ backgroundColor: 'blue' }} />
                            <Form.Check.Label>Blue</Form.Check.Label>
                        </Form.Check>
                        <Form.Check aria-label="radio 1" className="custom-radio">
                            <Form.Check.Input type="radio" style={{ backgroundColor: 'green' }} />
                            <Form.Check.Label>Green</Form.Check.Label>
                        </Form.Check>
                        <Form.Check aria-label="radio 1" className="custom-radio">
                            <Form.Check.Input type="radio" style={{ backgroundColor: 'yellow' }} />
                            <Form.Check.Label>Yellow</Form.Check.Label>
                        </Form.Check>
                        <Form.Check aria-label="radio 1" className="custom-radio">
                            <Form.Check.Input type="radio" style={{ backgroundColor: 'purple' }} />
                            <Form.Check.Label>Purple</Form.Check.Label>
                        </Form.Check>
                        <Form.Check aria-label="radio 1" className="custom-radio">
                            <Form.Check.Input type="radio" style={{ backgroundColor: 'olive' }} />
                            <Form.Check.Label>Olive</Form.Check.Label>
                        </Form.Check>
                        <Form.Check aria-label="radio 1" className="custom-radio">
                            <Form.Check.Input type="radio" style={{ backgroundColor: 'skyblue' }} />
                            <Form.Check.Label>Skyblue</Form.Check.Label>
                        </Form.Check>
                        <Form.Check aria-label="radio 1" className="custom-radio">
                            <Form.Check.Input type="radio" style={{ backgroundColor: 'navy' }} />
                            <Form.Check.Label>Navy</Form.Check.Label>
                        </Form.Check>
                    </div>
                </div>
                <div className="category-product-sidebar-cat">
                    <div className="category-product-sidebar-cat-hrader">
                        <p>Size</p>
                        <button type="button">
                            <img src={IconMinus} alt="" />
                        </button>
                    </div>
                    <div className="category-product-sidebar-cat-detail">
                        <Form.Check
                            inline
                            label="S"
                            name="group1"
                            type="checkbox"
                            className="custom-checkbox"
                        />
                        <Form.Check
                            inline
                            label="M"
                            name="group1"
                            type="checkbox"
                            className="custom-checkbox"
                        />
                        <Form.Check
                            inline
                            label="L"
                            name="group1"
                            type="checkbox"
                            className="custom-checkbox"
                        />
                        <Form.Check
                            inline
                            label="XL"
                            name="group1"
                            type="checkbox"
                            className="custom-checkbox"
                        />
                        <Form.Check
                            inline
                            label="XxL"
                            name="group1"
                            type="checkbox"
                            className="custom-checkbox"
                        />
                    </div>
                </div>
                <div className="category-product-sidebar-cat">
                    <div className="category-product-sidebar-cat-hrader">
                        <p>Age</p>
                        <button type="button">
                            <img src={IconMinus} alt="" />
                        </button>
                    </div>
                    <div className="category-product-sidebar-cat-detail">
                        <Form.Check
                            inline
                            label="0-3 Month"
                            name="group1"
                            type="checkbox"
                            className="custom-checkbox"
                        />
                        <Form.Check
                            inline
                            label="3-6 Month"
                            name="group1"
                            type="checkbox"
                            className="custom-checkbox"
                        />
                        <Form.Check
                            inline
                            label="6-12 Month"
                            name="group1"
                            type="checkbox"
                            className="custom-checkbox"
                        />
                        <Form.Check
                            inline
                            label="1 Year"
                            name="group1"
                            type="checkbox"
                            className="custom-checkbox"
                        />
                        <Form.Check
                            inline
                            label="2 Year"
                            name="group1"
                            type="checkbox"
                            className="custom-checkbox"
                        />
                        <Form.Check
                            inline
                            label="3-4 Year"
                            name="group1"
                            type="checkbox"
                            className="custom-checkbox"
                        />
                        <Form.Check
                            inline
                            label="5-6 Year"
                            name="group1"
                            type="checkbox"
                            className="custom-checkbox"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CategoryPageSidebar;
