import React, { useEffect, useState } from 'react';
import ConfigApi from '../../../../configs/ConfigApi';
import { useAuth } from '../../../contexts/AuthProvider';
import AxiosAuth from '../../../utils/AxiosAuth';
import InitInfo from '../../../utils/InitInfo';
import ReactGA4 from '../../../utils/ReactGA4';
import UserDashboardSection from '../components/UserDashboardSection';
import UserDashboardSectionDesktop from '../components/UserDashboardSectionDesktop';

function UsersDashboardPage() {
    const { currentUser } = useAuth();
    const { products } = InitInfo();

    const [memoAr, setMemoAr] = useState([]);
    const [memoItemAr, setMemoItemAr] = useState([]);

    useEffect(() => {
        AxiosAuth.currentUserAuth(currentUser)
            .get(ConfigApi.API_USER_ORDERS.replace(':type', 'all'))
            .then((response) => {
                setMemoAr(response.data.memoAr);
                setMemoItemAr(response.data.memoItemAr);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [currentUser]);

    return (
        <>
            <ReactGA4 />
            <div className="col-12 d-lg-none d-xl-none">
                <UserDashboardSection
                    currentUser={currentUser}
                    products={products}
                    memoItemAr={memoItemAr}
                    memoAr={memoAr}
                />
            </div>
            <div className="col-9 d-none d-lg-block d-xl-block">
                <UserDashboardSectionDesktop
                    currentUser={currentUser}
                    products={products}
                    memoItemAr={memoItemAr}
                    memoAr={memoAr}
                />
            </div>
        </>
    );
}

export default UsersDashboardPage;
