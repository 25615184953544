/* eslint-disable no-unused-vars */
export default function PageProperties(title, activeMen = ['home'], moveTop = true) {
    document.title = (title !== '' ? `${title} || ` : '') + process.env.REACT_APP_COMPANY_TITLE;
    // document.getElementById('#headerDescription').setAttribute('content', description);
    // document.getElementById('#headerKeywords').setAttribute('content', keywords);

    // {polytycs, bnp}

    if (moveTop) {
        window.scrollTo(0, 0);
    }
}
