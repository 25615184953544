/* eslint-disable import/no-unresolved */
import React from 'react';

export default function ProductPropertyDescription({ data }) {
    return (
        <div className="row">
            <div className="col-12">
                <div
                    dangerouslySetInnerHTML={{
                        __html: data.product_description,
                    }}
                />
            </div>
        </div>
    );
}
