import React from 'react';

function ProductReview() {
    return (
        <div className="row">
            <div className="col-4">
                <div>
                    Not Review Yet
                    {/* <h6>Average rating</h6>
                    <h3>0.5/5</h3>
                    {/* <h6>
                        <StarIcon />
                        <StarIcon />
                        <StarIcon />
                        <StarHalfIcon />
                        <StarOutlineIcon />
                    </h6> */}
                </div>
            </div>
            <div className="col-8">
                {/* <div>
                    <div className="feedback-progress-bar">
                        <p>5 Star</p>
                        <BorderLinearProgress variant="determinate" value={50} />
                        <p>59.56k</p>
                    </div>
                    <div className="feedback-progress-bar">
                        <p>5 Star</p>
                        <BorderLinearProgress variant="determinate" value={60} />
                        <p>59.56k</p>
                    </div>
                    <div className="feedback-progress-bar">
                        <p>5 Star</p>
                        <BorderLinearProgress variant="determinate" value={50} />
                        <p>59.56k</p>
                    </div>
                    <div className="feedback-progress-bar">
                        <p>5 Star</p>
                        <BorderLinearProgress variant="determinate" value={50} />
                        <p>59.56k</p>
                    </div>
                    <div className="feedback-progress-bar">
                        <p>5 Star</p>
                        <BorderLinearProgress variant="determinate" value={50} />
                        <p>59.56k</p>
                    </div>
                </div> */}
            </div>
        </div>
    );
}

export default function ProductPropertyReview() {
    return <ProductReview />;
}
