/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
export default function ValidatePhonePattern(countryCode, phone) {
    const countryCodeLength = countryCode.length;
    const countryCodeLastDigit = countryCode.substring(countryCodeLength - 1);

    // Remove Special Characters
    let phoneClean = phone.replace(/[^0-9]/g, '');

    // Remove 00 From Start
    const phonePreChar = phoneClean.substring(0, 2);
    if (phonePreChar === '00') {
        phoneClean = phoneClean.substring(2);
    }

    // Remove Country Code From Start
    const phoneCountryCode = phoneClean.substring(0, countryCodeLength);
    if (phoneCountryCode === countryCode) {
        phoneClean = phoneClean.substring(countryCodeLength);
    }

    // Remove Common Digit From Start
    const phoneCleanFirstDigit = phoneClean.substring(0, 1);
    if (phoneCleanFirstDigit === countryCodeLastDigit) {
        phoneClean = phoneClean.substring(1);
    }

    // Load Operator
    const operators = require(`./operator/operator-${countryCode}.json`);
    if (operators === null) {
        return false;
    }

    const operatorCodes = Object.keys(operators);
    const opFound = operatorCodes.find((opCode) => {
        const opCodeSize = opCode.length;

        // Remove Operator Code From Start
        const phoneCleanOpDigits = phoneClean.substring(0, opCodeSize);
        if (phoneCleanOpDigits === opCode) {
            const phoneCleanRem = phoneClean.substring(opCodeSize);

            if (phoneCleanRem.length === operators[opCode].subDigit) {
                return true;
            }
        }

        return false;
    });

    return !!opFound;
}
