/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import CrossCircle from '../../../../assets/images/cross-circle.svg';
import DeliveredIcon from '../../../../assets/images/deliveredIcon.svg';
import DeliveredIconRed from '../../../../assets/images/deliveredIconRed.svg';
import PlacedIcon from '../../../../assets/images/placedIcon.svg';
import ProcessingIcon from '../../../../assets/images/processingIcon.svg';
import ProcessingIconRed from '../../../../assets/images/processingIconRed.svg';
import ShippedIcon from '../../../../assets/images/shippedIcon.svg';
import ShippedIconRed from '../../../../assets/images/shippedIconRed.svg';
import ConfigApi from '../../../../configs/ConfigApi';
import { useAuth } from '../../../contexts/AuthProvider';
import AxiosAuth from '../../../utils/AxiosAuth';
import FindInArray from '../../../utils/FindInArray';
import InitInfo from '../../../utils/InitInfo';
import PageProperties from '../../../utils/PageProperties';
import ReactGA4 from '../../../utils/ReactGA4';

// const getInvoiceData = (params) =>
//     new Promise((resolve, reject) => {
//         AxiosAuth.get(ConfigApi.API_INVOICE, {
//             params,
//         })
//             .then((response) => {
//                 resolve(response);
//             })
//             .catch((err) => {
//                 reject(err);
//             });
//     });

function OrderDetailProducts({ invoiceItemData, products }) {
    const FindArr = new FindInArray();
    FindArr.init(products, 'id');

    return (
        <div className="order-section-detail-products">
            <div className="row">
                <div className="col-12 ">
                    {invoiceItemData &&
                        invoiceItemData.map((item) => {
                            const itemData = FindArr.find(item.product_sl);

                            return (
                                <div className="row bottom-border" key={item.product_sl}>
                                    <div className="col-4 col-lg-2">
                                        <div className="user-orders-img">
                                            <img
                                                src={`https://www.optimizee.xyz/images/${btoa(
                                                    itemData.default_image
                                                )},200,300.jpg`}
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                    <div className="col-8 col-lg-5">
                                        <div className="order-section-detail-title">
                                            <Link
                                                to={`/products/${item.productId}/${itemData.title}`}
                                            >
                                                <b>{itemData.title}</b>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-2 order-viewQty">
                                        {item.price_offer}x{item.quantity}
                                    </div>
                                    <div className="col-1 order-viewQty">
                                        {(item.price_offer * item.quantity).toFixed(2)}
                                    </div>
                                    <div className="col-2 order-viewStatus">
                                        {/* <button
                                            type="button"
                                            className="order-section-detail-cancle-btn"
                                        >
                                            Cancel
                                        </button> */}
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
}
function OrderDetailTimeline({ timeLineAr }) {
    return (
        <div className="order-section-timeline">
            <div className="order-section-timeline-p-m-0">
                <div className="order-section-timeline-item">
                    <div className="order-section-timeline-line-blank-fill" />
                    <div className="order-section-timeline-circle-fill">
                        <img src={PlacedIcon} alt="" />
                    </div>
                    <div className="order-section-timeline-line-fill" />
                </div>
                <div className="order-section-timeline-title">Placed</div>
            </div>
            <div className="order-section-timeline-p-m-0">
                {!timeLineAr.processing ? (
                    <div className="order-section-timeline-line-joint">
                        <div className="order-section-timeline-line-joint-in" />
                    </div>
                ) : (
                    ''
                )}
                {timeLineAr.processing ? (
                    <div className="order-section-timeline-item">
                        <div className="order-section-timeline-line-fill" />
                        <div className="order-section-timeline-circle-fill">
                            <img src={ProcessingIcon} alt="" />
                        </div>
                        <div className="order-section-timeline-line-fill" />
                    </div>
                ) : (
                    <div className="order-section-timeline-item">
                        <div className="order-section-timeline-line-not-fill" />
                        <div className="order-section-timeline-circle-not-fill">
                            <img src={ProcessingIconRed} alt="Processing Red" />
                            {timeLineAr.canceled ? (
                                <img
                                    src={CrossCircle}
                                    alt=""
                                    style={{ width: 50, height: 37, top: '-19px' }}
                                />
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="order-section-timeline-line-not-fill" />
                    </div>
                )}
                <div className="order-section-timeline-title">Processing</div>
            </div>
            <div className="order-section-timeline-p-m-0">
                {!timeLineAr.shipped && timeLineAr.processing ? (
                    <div className="order-section-timeline-line-joint">
                        <div className="order-section-timeline-line-joint-in" />
                    </div>
                ) : (
                    ''
                )}
                {timeLineAr.shipped ? (
                    <div className="order-section-timeline-item">
                        <div className="order-section-timeline-line-fill" />
                        <div className="order-section-timeline-circle-fill">
                            <img src={ShippedIcon} alt="" />
                        </div>
                        <div className="order-section-timeline-line-fill" />
                    </div>
                ) : (
                    <div className="order-section-timeline-item">
                        <div className="order-section-timeline-line-not-fill" />
                        <div className="order-section-timeline-circle-not-fill">
                            <img src={ShippedIconRed} alt="" />
                            {timeLineAr.canceled ? (
                                <img
                                    src={CrossCircle}
                                    alt=""
                                    style={{ width: 50, height: 37, top: '-19px' }}
                                />
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="order-section-timeline-line-not-fill" />
                    </div>
                )}
                <div className="order-section-timeline-title">Shipped</div>
            </div>
            <div className="order-section-timeline-p-m-0">
                {!timeLineAr.delivered && timeLineAr.shipped ? (
                    <div className="order-section-timeline-line-joint">
                        <div className="order-section-timeline-line-joint-in" />
                    </div>
                ) : (
                    ''
                )}
                {timeLineAr.delivered ? (
                    <div className="order-section-timeline-item">
                        <div className="order-section-timeline-line-fill" />
                        <div className="order-section-timeline-circle-fill">
                            <img src={DeliveredIcon} alt="" />
                        </div>
                    </div>
                ) : (
                    <div className="order-section-timeline-item">
                        <div className="order-section-timeline-line-not-fill" />

                        <div className="order-section-timeline-circle-not-fill">
                            <img src={DeliveredIconRed} alt="" />
                            {timeLineAr.canceled ? (
                                <img
                                    src={CrossCircle}
                                    alt=""
                                    style={{ width: 50, height: 37, top: '-19px' }}
                                />
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                )}
                <div className="order-section-timeline-title">Delivered</div>
            </div>
        </div>
    );
}
function OrderDetailTracking({ timeLineDetAr }) {
    let sho = 0;
    return (
        <div className="order-section-detail-tracking">
            <div className="order-section-detail-tracking-title">
                Tracking: <b>#PPR10089784475</b>
            </div>
            <hr />
            <div className="order-section-detail-tracking-history">
                <ul>
                    {Object.values(timeLineDetAr)?.map((tRow) => {
                        sho += 1;
                        return (
                            <li key={tRow.sl}>
                                <b className={sho === 1 ? 'history-title' : 'history-title2'}>
                                    Your product has been {tRow.status}
                                </b>
                                <br />
                                <p>{tRow.held_time}</p>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
}
function OrderDetailInfo({ invoiceData, invoiceItemData, addressAr }) {
    let totalAmount = 0;
    return (
        <div className="order-section-detail-info">
            <div className="order-section-detail-info-title order-border-bottom">
                <div className="row">
                    <div
                        className="col-6"
                        style={{
                            marginBottom: '10px',
                        }}
                    >
                        <h5 className="user-order-section-number">
                            Order Id: #
                            <Link to={`/user/order-details/${invoiceData.id}/`}>
                                INV {invoiceData.id}
                            </Link>
                        </h5>
                        <h6 className="user-order-section-time">
                            Placed on {invoiceData.dateIssue}
                        </h6>
                    </div>
                    <div
                        className="col-6"
                        style={{
                            marginBottom: '10px',
                        }}
                    >
                        <button
                            type="button"
                            className="ml-3 float-right user-order-section-btn-detail"
                            style={{ width: 150 }}
                        >
                            Contact Support
                        </button>
                        {
                            invoiceData.paymentStatus !== 'due' ? (
                                <button
                                    type="button"
                                    className="float-right user-order-section-btn-review"
                                >
                                    Write a Review
                                </button>
                            ) : (
                                ''
                            )
                            // (
                            //     <Link
                            //         to={`/invoice/${invoiceData.id}/payment/`}
                            //         className="ml-3 float-right user-order-section-btn-detail-pay"
                            //         style={{ paddingTop: 7 }}
                            //     >
                            //         Payment Now
                            //     </Link>
                            // )
                        }
                    </div>
                </div>
            </div>
            <div className="order-section-detail-info-address">
                <div className="row" style={{ alignItems: 'center' }}>
                    <div className="col-4 order-section-detail-info-title">Shipping Address</div>
                    <div className="col-3 order-section-detail-info-data">
                        {addressAr?.shipping?.name}
                    </div>
                    <div className="col-5 order-section-detail-info-data">
                        {addressAr?.shipping?.line1}, {addressAr?.shipping?.line2},
                        {addressAr?.shipping?.city}, {addressAr?.shipping?.state},
                        {addressAr?.shipping?.zip}, {addressAr?.shipping?.country}
                    </div>
                </div>
            </div>
            <div className="order-section-detail-info-payment">
                <div className="row" style={{ alignItems: 'center' }}>
                    <div className="col-4 order-section-detail-info-title">Payment Status</div>
                    {invoiceData?.paymentStatus === 'due' ? (
                        <>
                            <div
                                className="col-3 order-section-detail-info-data"
                                style={{ color: 'red' }}
                            >
                                Unpaid
                                <br />
                                Pay within {invoiceData?.dateDue}
                            </div>
                            <div className="col-5">
                                {invoiceData.paymentStatus === 'due' &&
                                    invoiceData.status !== 'canceled' ? (
                                    <Link
                                        to={`/invoice/${invoiceData.id}/payment/`}
                                        className="ml-3 float-right user-order-section-btn-detail-pay"
                                        style={{ paddingTop: 7 }}
                                    >
                                        Payment Now
                                    </Link>
                                ) : (
                                    <Link
                                        to="/user/canceled-order/"
                                        className="ml-3 float-right user-order-section-btn-detail-pay"
                                        style={{ paddingTop: 7 }}
                                    >
                                        Canceled
                                    </Link>
                                )}
                            </div>
                        </>
                    ) : (
                        <div
                            className="col-6  order-section-detail-info-data"
                            style={{ textTransform: 'uppercase' }}
                        >
                            {invoiceData?.paymentStatus}
                        </div>
                    )}
                </div>
            </div>

            <div className="order-section-detail-info-payment-history">
                {invoiceItemData &&
                    invoiceItemData.map((item) => {
                        totalAmount += item.price_offer * item.quantity;
                        return null;
                    })}
                <table>
                    <tbody>
                        <tr>
                            <td className="order-section-detail-info-title">Subtotal</td>
                            <td className="order-section-detail-info-data">
                                {totalAmount.toFixed(2)}
                            </td>
                        </tr>
                        <tr>
                            <td className="order-section-detail-info-title">Shipping Fee</td>
                            <td className="order-section-detail-info-data">
                                {invoiceData.shippingCharge}
                            </td>
                        </tr>
                        <tr>
                            <td className="order-section-detail-info-title">Platform Promotion</td>
                            <td className="order-section-detail-info-data">0.00</td>
                        </tr>
                        <tr>
                            <td className="order-section-detail-info-title">Order Total</td>
                            <td className="order-section-detail-info-data">
                                {(totalAmount + invoiceData.shippingCharge).toFixed(2)}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

function UserOrderDetailPage() {
    const { products } = InitInfo();
    const { currentUser } = useAuth();

    const [invoiceItemData, setInvoiceItemData] = useState([]);
    const [invoiceData, setInvoiceData] = useState([]);
    const { invoiceId } = useParams();
    const [addressAr, setAddressAr] = useState([]);
    const [timeLineAr, setTimeLineAr] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [timeLineDetAr, setTimeLineDetAr] = useState([]);

    useEffect(() => {
        // Collect API Data
        AxiosAuth.currentUserAuth(currentUser)
            .get(ConfigApi.API_INVOICE.replace(':invoiceId', invoiceId))
            .then((response) => {
                setInvoiceData(response.data.invoiceInfo);
                setInvoiceItemData(response.data.invoiceItems);
                setAddressAr(response.data.invoiceInfo.addresses);
                setTimeLineAr(response.data.invoiceTimeLine);
                setTimeLineDetAr(response.data.invoiceTimeLineDetailAr);
            })
            .catch((err) => {
                console.log(err);
            });

        // Collect invoice data
    }, [currentUser, invoiceId]);

    if (!invoiceData) {
        return null;
    }
    PageProperties('Orders');

    return (
        <div className="col-12 col-lg-9">
            <ReactGA4 />
            <div className="order-section-detail">
                <OrderDetailTimeline timeLineAr={timeLineAr} />
                {timeLineAr.length !== 0 ? <OrderDetailTracking timeLineDetAr={timeLineAr} /> : ''}
                <OrderDetailInfo
                    invoiceData={invoiceData}
                    invoiceItemData={invoiceItemData}
                    addressAr={addressAr}
                />
                <OrderDetailProducts invoiceItemData={invoiceItemData} products={products} />

                {/* <OrderDetailRelatedProduct products={products} /> */}
            </div>
        </div>
    );
}

export default UserOrderDetailPage;
