import { faEnvelope, faEnvelopeOpen } from '@fortawesome/free-regular-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import ConfigApi from '../../../../configs/ConfigApi';
import { useAuth } from '../../../contexts/AuthProvider';
import { useTemplate } from '../../../contexts/TemplateProvider';
import AxiosAuth from '../../../utils/AxiosAuth';

function UserEmailChnageModal({ show, setShow }) {
    const { currentUser } = useAuth();
    const refEmail = useRef();
    const refOtp = useRef();
    const [newEmail, setNewEmail] = useState();

    const [userProvider, setUserProvider] = useState();
    const { setMessage } = useTemplate();
    const [selectedEmail, setSelectedEmail] = useState(currentUser.email);
    const [showEmailInput, setShowEmailInput] = useState(false);
    const [showOtpInput, setShowOtpInput] = useState(false);
    const [uKey, setUKey] = useState(false);

    const handleSendEmailForOtp = () => {
        AxiosAuth.currentUserAuth(currentUser)
            .post(ConfigApi.API_USER_ADD_EMAIL_OTP, {
                adEmail: refEmail.current.value,
            })
            .then(({ data }) => {
                setMessage({ text: `${data.message}`, timeout: 10000 });
                if (data.error === 0) {
                    setNewEmail(refEmail.current.value);
                    setShowOtpInput(true);
                    setShowEmailInput(false);
                    setUKey(data.key);
                }
            });
    };
    const handleVerifyOtp = () => {
        AxiosAuth.currentUserAuth(currentUser)
            .post(ConfigApi.API_USER_VERIFY_EMAIL_OTP, {
                uKey,
                otp: refOtp.current.value,
                newEmail,
            })
            .then(({ data }) => {
                setMessage({ text: `${data.message}`, timeout: 10000 });
                if (data.error === 0) {
                    setShowOtpInput(false);
                    setShowEmailInput(false);
                    setUserProvider(data.provider);
                }
            });
    };

    const handleChnageDefaultEmail = (ev) => {
        AxiosAuth.currentUserAuth(currentUser)
            .post(ConfigApi.API_USER_DEFAULT_EMAIL_CHANGE, {
                email: ev,
            })
            .then(({ data }) => {
                setMessage({ text: `${data.message}`, timeout: 10000 });
                if (data.error === 0) {
                    setSelectedEmail(ev);
                }
            });
    };
    const handleRemoveEmail = (ev) => {
        AxiosAuth.currentUserAuth(currentUser)
            .post(ConfigApi.API_USER_DEFAULT_EMAIL_REMOVE, {
                email: ev,
            })
            .then(({ data }) => {
                setMessage({ text: `${data.message}`, timeout: 10000 });
                if (data.error === 0) {
                    setUserProvider(data.provider);
                }
            });
    };

    useEffect(() => {
        AxiosAuth.currentUserAuth(currentUser)
            .get(ConfigApi.API_PROVIDER_DATA)
            .then(({ data }) => {
                setUserProvider(data);
            });
    }, [currentUser]);

    const userProviderUnique = userProvider?.filter(
        (ele, ind) =>
            ind ===
            userProvider?.findIndex((elem) => elem.email === ele.email && elem.email === ele.email)
    );

    return (
        <Modal
            size="md"
            show={show}
            onHide={() => {
                setShow(false);
            }}
            centered
            aria-labelledby="example-modal-sizes-title-lg"
            contentClassName="custom-profile-change-content btn-close-custom"
        >
            <Modal.Header closeButton>
                <Modal.Title>Manage Email</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <table style={{ width: '100%', marginBottom: 10 }}>
                    <thead>
                        <tr>
                            <td colSpan={2}>Email</td>
                            <td>Default</td>
                            <td>Remove</td>
                        </tr>
                    </thead>
                    <tbody>
                        {userProviderUnique?.map((pro) => (
                            <tr style={{ height: 40 }} key={pro.email}>
                                <td>
                                    {selectedEmail === pro.email ? (
                                        <FontAwesomeIcon
                                            icon={faEnvelopeOpen}
                                            style={{ marginRight: 8 }}
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            icon={faEnvelope}
                                            style={{ marginRight: 8 }}
                                        />
                                    )}
                                </td>
                                <td>{pro.email}</td>
                                <td style={{ textAlign: 'center' }}>
                                    <Form.Check
                                        checked={selectedEmail === pro.email}
                                        onChange={() => {
                                            handleChnageDefaultEmail(pro.email);
                                        }}
                                        name="radio-button-demo"
                                        type="radio"
                                        className="user-address-radio-check"
                                    />
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                    {selectedEmail !== pro.email ? (
                                        <FontAwesomeIcon
                                            icon={faXmark}
                                            onClick={() => {
                                                handleRemoveEmail(pro.email);
                                            }}
                                            style={{ color: 'red' }}
                                        />
                                    ) : (
                                        ''
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <Form>
                    <Form.Group className="custom-login-input">
                        <Form.Label
                            className="custom-label"
                            onClick={() => setShowEmailInput(true)}
                        >
                            Add Email
                        </Form.Label>
                        {showEmailInput && !showOtpInput ? (
                            <>
                                <Form.Control
                                    className="custom-input mb-4"
                                    type="email"
                                    placeholder="example@example.com"
                                    name="add_email"
                                    ref={refEmail}
                                />
                                <Button
                                    variant="primary"
                                    type="button"
                                    onClick={handleSendEmailForOtp}
                                    className="custom-login-btn"
                                    name="birthDate"
                                >
                                    Save Email
                                </Button>
                            </>
                        ) : (
                            ''
                        )}
                        {showOtpInput && !showEmailInput ? (
                            <>
                                <Form.Control
                                    className="custom-input-otp text-center"
                                    type="email"
                                    placeholder="OTP"
                                    ref={refOtp}
                                />
                                <Button
                                    variant="primary"
                                    type="button"
                                    onClick={handleVerifyOtp}
                                    className="custom-login-btn"
                                    name="birthDate"
                                >
                                    Verify OTP
                                </Button>
                            </>
                        ) : (
                            ''
                        )}
                    </Form.Group>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default UserEmailChnageModal;
