import React from 'react';
import { Card, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FindInArray from '../../../utils/FindInArray';
import InitInfo from '../../../utils/InitInfo';
import Optimizee from '../../../utils/Optimizee';
import StatusBtn from '../../../utils/StatusBtn';

function CartProductVariant({ data }) {
    if (data) {
        return Object.keys(data).map((key) => <li key={key}>{data[key]}</li>);
    }

    return null;
}
function TableData({ rowData, qty, variation }) {
    return (
        <tr key={rowData.id} className={['tableRowHover', 'tableTr '].join(' ')}>
            <td>
                <img
                    style={{ width: '64px' }}
                    className=""
                    src={Optimizee(rowData.default_image, 100)}
                    alt={rowData.title}
                />
            </td>
            <td align="left">
                <b> {rowData.title}</b>
                <br />
                <ul className="variant-list">
                    <CartProductVariant
                        data={Object.values(variation).slice(0, Object.keys(variation).length - 2)}
                    />
                </ul>
            </td>
            <td align="center">{qty}</td>
            <td align="center">{rowData.priceOffer.toFixed(2)}</td>
            <td align="center" width="10">
                {(rowData.priceOffer * qty).toFixed(2)}
            </td>
        </tr>
    );
}
function InvoicePageSection({ productData, invoiceData, invoiceItemData, allInvoice, address }) {
    const { shopData } = InitInfo();
    let paymentStatus = '';
    if (invoiceData.net_amount <= invoiceData.paidAmount && invoiceData.paidAmount !== 0) {
        paymentStatus = 'Paid';
    } else if (invoiceData.net_amount > invoiceData.paidAmount && invoiceData.paidAmount !== 0) {
        paymentStatus = 'Partial Paid';
    } else {
        paymentStatus = 'Not Paid';
    }
    const FindArr = new FindInArray();
    FindArr.init(productData, 'id');
    let totalAmount = 0;
    return (
        <section className="cart-section">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-3 col-md-3">
                        <Card>
                            <div className="col-12 invoice-head">
                                <h6>ORDERS</h6>
                            </div>

                            {allInvoice.map((invoice) => (
                                <Link
                                    to={`/invoice/${invoice.id}`}
                                    className="invoice-list-link"
                                    key={invoice.id}
                                >
                                    <div className="invoice-list">
                                        <div className="invoice-list-detail">
                                            <div className="invoice-list-detail-time">
                                                {invoice.time_created}
                                            </div>
                                            <div className="invoice-list-order-no">
                                                <b>INV {invoice.id}</b>
                                            </div>
                                        </div>
                                        <div className="invoice-list-status">
                                            <StatusBtn status={invoice.status} />
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </Card>
                    </div>
                    <div className="col-12 col-lg-9 col-md-9">
                        <Card>
                            <div className="row" style={{ padding: '10px' }}>
                                <div className="col-12 invoice-head">
                                    <h3>INVOICE</h3>
                                </div>
                                <div className="col-6">
                                    <div className="invoice-print-section">
                                        <span className="invoice-print-btn">
                                            <Link
                                                target="blank"
                                                to={`/invoice/${invoiceData.id}/print`}
                                            >
                                                Print
                                            </Link>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="order-detail-section-order-no">
                                        <span>{paymentStatus}</span>
                                        <br />
                                        <h6>INV-{invoiceData.id}</h6>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="order-detail-section-title">
                                        <h3>INVOICE FROM</h3>
                                        <p>{shopData.shopInfo.shop_name}</p>
                                        <p style={{ lineHeight: '14px' }}>
                                            {shopData.shopInfo.address}
                                        </p>
                                        <p>{shopData.shopInfo.contact_number}</p>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="order-detail-section-title">
                                        <h3>INVOICE TO</h3>
                                        <p>{address.name}</p>
                                        <p>{address.area}</p>
                                        <p>{address.city}</p>
                                        <p>{address.district}</p>
                                        <p>{address.phone}</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="tableContainer">
                                        <Table className="" aria-label="simple table">
                                            <thead>
                                                <tr className="tableTr">
                                                    <th>Description</th>
                                                    <th />
                                                    <th align="center">Qty</th>
                                                    <th align="center">Unit Price</th>
                                                    <th align="center">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {invoiceItemData.map((row) => {
                                                    totalAmount += row.price * row.quantity;
                                                    const itemData = FindArr.find(row.productId);
                                                    return (
                                                        <TableData
                                                            qty={row.quantity}
                                                            key={`${row.productId}-${itemData.title}`}
                                                            rowData={itemData}
                                                            variation={row.product_variation_json}
                                                        />
                                                    );
                                                })}
                                            </tbody>
                                            <tbody>
                                                <tr>
                                                    <td colSpan="4" align="right">
                                                        Subtotal
                                                    </td>
                                                    <td align="center">{totalAmount.toFixed(2)}</td>
                                                </tr>

                                                <tr>
                                                    <td colSpan="4" align="right">
                                                        Shipping
                                                    </td>
                                                    <td align="center">50.00</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="4" align="right">
                                                        Total
                                                    </td>
                                                    <td align="center">
                                                        {(totalAmount + 50).toFixed(2)}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default InvoicePageSection;
