/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthProvider';
import { useCart } from '../../../contexts/CartProvider';

// function priceCalculator(cartProducts) {
//     let tItem = 0;

//     cartProducts?.forEach((itemAr) => {
//         itemAr.productVariation.forEach((variation) => {
//             tItem += variation.quantity;
//         });
//     });

//     return { totalItem: tItem };
// }

function CartOption() {
    const { totalItem } = useCart();
    const { isSignIn, openLoginModal } = useAuth();
    const navigate = useNavigate();
    const url = '/cart/';

    return (
        <div className="top-options">
            {!isSignIn ? (
                <span
                    className="top-icon"
                    role="button"
                    onClick={() => {
                        openLoginModal(() => {
                            navigate(url);
                        }, null);
                    }}
                >
                    <FontAwesomeIcon
                        icon={faShoppingCart}
                        size="lg"
                        className="user-operation-icon"
                    />
                    <div className="header-cart-title">Cart</div>
                </span>
            ) : (
                <Link to={url} className="top-icon" role="button">
                    <FontAwesomeIcon
                        icon={faShoppingCart}
                        size="lg"
                        className="user-operation-icon"
                    />
                    {totalItem > 0 && <span className="cart-item">{totalItem}</span>}
                    <div className="header-cart-title">Cart</div>
                </Link>
            )}
        </div>
    );
}

export default CartOption;
